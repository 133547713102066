import { useEffect, useState } from 'react';
import style from './element.css';
import ImageUploading from 'react-images-uploading';

export const FileInput = ({ element, register, style, control, setValue }) => {
	const [images, setImages] = useState([]);
	const maxNumber = 20;
	const onChange = (imageList, addUpdateIndex) => {
		setImages(imageList);
		imageList = imageList.map((item) => item.file);
		setValue(element.name, imageList);
	};
	const changeOrder = (index, order) => {
		const swapIndex = order == 'up' ? index - 1 : index + 1;
		let imageSwap = images;
		const currentImage = imageSwap[index];
		const swapImage = imageSwap[swapIndex];
		imageSwap[swapIndex] = currentImage;
		imageSwap[index] = swapImage;

		onChange(imageSwap);
	};

	useEffect(() => {
		register(element.name);
		setValue(element.name, []);
	}, []);

	return (
		<div className='upload__image-wrapper'>
			<ImageUploading
				multiple={element.multiple}
				value={images}
				onChange={onChange}
				maxNumber={maxNumber}
				dataURLKey='data_url'>
				{({
					imageList,
					onImageUpload,
					onImageRemoveAll,
					onImageUpdate,
					onImageRemove,
					isDragging,
					dragProps
				}) => (
					// write your building UI
					<div className='upload__image-container'>
						<div className='button_wrapper'>
							<span
								style={isDragging ? { color: 'red' } : undefined}
								onClick={onImageUpload}
								{...dragProps}>
								Ajouter
							</span>
							<span onClick={onImageRemoveAll}>
								Supprimer toutes les images
							</span>
						</div>

						<div className='preview_container'>
							{imageList.map((image, index) => (
								<div key={index} className='image-item'>
									<img
										src={image['data_url']}
										alt=''
										className='image_preview'
									/>
									<div className='image-item__btn-wrapper'>
										<span onClick={() => onImageUpdate(index)}>Modifier</span>
										<span onClick={() => onImageRemove(index)}>Supprimer</span>
										{element.multiple && (
											<>
												{index != 0 && (
													<span
														onClick={() => changeOrder(index, 'up')}
														className='bg-green-500 rounded-full w-6 h-6 flex items-center justify-center'>
														<i className='fa fa-chevron-up'></i>
													</span>
												)}
												{index != imageList.length - 1 && (
													<span
														onClick={() => changeOrder(index, 'down')}
														className='bg-green-500 rounded-full w-6 h-6 flex items-center justify-center'>
														<i className='fa fa-chevron-down'></i>
													</span>
												)}
											</>
										)}
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			</ImageUploading>
		</div>
	);
};
