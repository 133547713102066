import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ContactSection from './details/ContactSection';
import { useContact } from '../../service/contact/useContact';
import { GeneralSpinner } from '../../components/commons/general-spinner';
import StatusSection from '../../components/generals/contact/section/status.section';
import ContactCoupleSection from './details/ContactCoupleSection';
import { ContactCorporation } from './details/ContactCorporation';

function ContactDetailsPage() {
	const [contact, setContact] = useState();
	const { contactId } = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		if (!contact) {
			useContact
				.getContactByUuid(contactId)
				.then((response) => {
					setContact(response.data);
				})
				.catch(() => {});
		}
	}, [contact, location, contactId]);

	return (
		(contact && (
			<div className='border-2 border-solid border-[#2d5c6a]'>
				<StatusSection contact={contact} navigate={navigate} />

				{contact.target === 'single person' && (
					<ContactSection contact={contact} />
				)}
				{contact.target === 'couple' && (
					<ContactCoupleSection contact={contact} />
				)}
				{contact.target === 'corporation' && (
					<ContactCorporation contact={contact} />
				)}
			</div>
		)) || <GeneralSpinner />
	);
}

export default ContactDetailsPage;
