import { requiredField } from '../../../validation/validation';
import { CRITERE, CRITERIA_IN_NUMBER, TYPE_OFFRE } from '../../type';

export const Terrain = [
	{
		//Emprise  sol
		group: 'terrain',
		className: 'w-full sm:w-1/2 ',
		type: 'text',
		title: 'Emprise au sol :',
		name: 'ground_print',
		tagName: 'terrain.ground_print',
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Emprise  sol résiduelle
		group: 'terrain',
		className: 'w-full sm:w-1/2 ',
		type: 'text',

		title: 'Emprise au sol résiduelle:',
		name: 'ground_print_residual',
		tagName: 'terrain.ground_print_residual',
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Shon
		group: 'terrain',
		className: 'w-full sm:w-1/2 ',
		type: 'text',
		title: 'Shon:',
		name: 'shon',
		tagName: 'terrain.shon',
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//CES
		group: 'terrain',
		className: 'w-full sm:w-1/2 ',
		type: 'text',
		title: 'CES:',
		name: 'ces',
		tagName: 'terrain.ces',
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//POS
		group: 'terrain',
		className: 'w-full sm:w-1/2 ',
		type: 'text',
		title: 'POS:',
		name: 'pos',
		tagName: 'terrain.pos',
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Codification plu
		group: 'terrain',
		className: 'w-full sm:w-1/2 ',
		type: 'text',
		title: 'Codification Plu:',
		name: 'codification_plu',
		tagName: 'terrain.codification_plu',
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Droit de passage
		group: 'terrain',
		className: 'w-full sm:w-1/2 ',
		type: 'text',
		title: 'Droit de passage:',
		tagName: 'terrain.right_way',
		name: 'right_way',
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Référence cadastrale
		group: 'terrain',
		className: 'w-full sm:w-1/2 ',
		type: 'text',
		title: 'Référence cadastrale:',
		tagName: 'terrain.cadastral_ref',
		name: 'cadastral_ref',
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Piscinable
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'radio',
		title: 'Piscinable :',
		name: 'swimming',
		tagName: 'terrain.ground.swimming',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Arboré
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'radio',
		title: 'Arboré :',
		// validation: {
		// 	validate: { requiredField },
		// },
		name: 'tree',
		tagName: 'terrain.ground.tree',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Divisible
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'radio',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Divisible :',
		name: 'divisible',
		tagName: 'terrain.ground.divisible',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Viabilisé
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'radio',
		title: 'Viabilisé :',
		// validation: {
		// 	validate: { requiredField },
		// },
		name: 'serviced',
		tagName: 'terrain.ground.serviced',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//RACCORDEMENTS
		group: 'info_personnelle',
		// validation: {
		// 	validate: { requiredField },
		// },
		className: 'w-full',
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//eau
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		// validation: {
		// 	validate: { requiredField },
		// },
		type: 'radio',
		title: 'Eau :',
		name: 'water',
		tagName: 'terrain.ground.water',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Gaz
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		// validation: {
		// 	validate: { requiredField },
		// },
		type: 'radio',
		title: 'Gaz :',
		name: 'gaz_ground',
		tagName: 'terrain.ground.gaz',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Elec
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		// validation: {
		// 	validate: { requiredField },
		// },
		type: 'radio',
		title: 'Elec :',
		name: 'elec',
		tagName: 'terrain.ground.elec',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Tel
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'radio',
		title: 'Tel :',
		name: 'tel',
		tagName: 'terrain.ground.tel',
		// validation: {
		// 	validate: { requiredField },
		// },
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: [TYPE_OFFRE.VENTE]
			},
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	}
];
