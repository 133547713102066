import React from 'react';
import { Link } from 'react-router-dom';

function Chevron({ link, state, action }) {
	return (
		<>
			{(action && (
				<span onClick={() => action()}>
					<i className='fa-2xl fa-solid fa-chevron-right '></i>
				</span>
			)) || (
				<Link to={link} state={state}>
					<i className='fa-2xl fa-solid fa-chevron-right '></i>
				</Link>
			)}
		</>
	);
}

export default Chevron;
