import React from 'react';
import { FooterBlock } from './footer-bock';

export const Footer = React.memo(() => {
	return (
		<>
			<FooterBlock />
			<footer className='text-center mx-auto w-full'>
				{new Date().getFullYear()} © | Tous droits réservés | Contactez le
				Support |{' '}
				<a href='/cgv' className='cursor-pointer'>
					CGV
				</a>{' '}
				|{' '}
				<a href='/terms-and-conditions' className='cursor-pointer'>
					Mentions légales & Politique de confidentialité
				</a>{' '}
				|{' '}
				<a href='/cookies-conditions' className='cursor-pointer'>
					Politique de cookies{' '}
				</a>
			</footer>
		</>
	);
});
