import { CONDITION, FIELD_TYPE } from '../input';

export const requiredField = (value) => {
	return value === '' || value === undefined || !value
		? 'Le champ est requis'
		: true;
};
export const greaterThanZero = (value) => {
	return value <= 0 ? 'La valeur doit être supérieur ou égale à 0' : true;
};

export const lessThanHundred = (value) => {
	return value > 100 ? 'La valeur doit être inférieur ou égale à 100%' : true;
};

export const validateEmail = (value) => {
	if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) {
		return "L'adresse email est invalide";
	}
	return true;
};

export const requiredIf = (fieldValue, fields) => {
	let results = [];
	for (let i = 0; i < fields.length; i++) {
		let fieldToCheck = document.querySelector(`[name=${fields[i].field}]`);
		if (fields[i].fieldType == FIELD_TYPE.RADIO) {
			fieldToCheck = document.querySelector(
				`[name=${fields[i].field}]:checked`
			);
		}

		// Il est autorisé à être required
		let isAllowed = fieldToCheck?.value != fields[i].value;
		let message = `Le champ est requis si le champ ${fields[i].displayed.field} est à ${fields[i].displayed.value}`;
		if (fields[i].condition == CONDITION.INEQUAL) {
			isAllowed = fieldToCheck?.value == fields[i].value;
			message = `Le champ est requis si le champ ${fields[i].displayed.field} est autre que ${fields[i].displayed.value}`;
		}
		results.push({
			index: i,
			message,
			isAllowed,
		});
	}

	return results.every((item) => item.isAllowed)
		? true
		: !fieldValue
		? results.filter((item) => !item.isAllowed)[0].message
		: true;
};
