export const ALREADY_USED_CRITERIA = [
	'price',
	'room',
	'city',
	'bien',
	'level',
	'city',
	'offre',
	'surface',
	'bedroom',
];

export const ATTRIBUTES = [
	{
		attribut: 'wc',
		label: 'Toilettes',
	},
	{
		attribut: 'nbOfSleeping',
		label: 'Salle de couchage',
	},
	{
		attribut: 'nbOfBathroom',
		label: 'Nombre de salle de bain',
	},
	{
		attribut: 'nbOfRoomWater',
		label: "Nombre de salle d'eau",
	},
	{
		attribut: 'nbOfWc',
		label: 'Nombre de wc',
	},
	{
		attribut: 'nbOfLots',
		label: 'Nombre de lots',
	},
	{
		attribut: 'gaz',
		label: 'Gaz',
	},

	{
		attribut: 'water',
		label: 'Eau',
	},

	{
		attribut: 'elec',
		label: 'Electricité',
	},
	{
		attribut: 'tel',

		label: 'Téléphone',
	},

	{
		attribut: 'logia',
		label: 'Loggia',
	},
	{
		attribut: 'cellar',
		label: 'Cave',
	},
	{
		attribut: 'garage',
		label: 'Garage',
	},
	{
		attribut: 'jardin',
		label: 'Jardin',
	},
	{
		attribut: 'balcony',
		label: 'Balcon',
	},
	{
		attribut: 'teracce',
		label: 'Terrasse',
	},
	{
		attribut: 'veranda',
		label: 'Véranda',
	},
	{
		attribut: 'vitrage',
		label: 'Vitrage',
	},
	{
		attribut: 'basement',
		label: 'Sous-sol',
	},
	{
		attribut: 'elevator',
		label: 'Ascenseur',
	},
	{
		attribut: 'varangue',
		label: 'Varangue',
	},
	{
		attribut: 'fireplace',
		label: 'Cheminée',
	},
	{
		attribut: 'stay_area',
		label: 'Zone de séjour',
	},
	{
		attribut: 'carrez_area',
		label: 'Surface Carrez',
	},
	{
		attribut: 'mitoyennety',
		label: 'Mitoyenneté',
	},
	{
		attribut: 'ground_floor',
		label: 'Rez-de-chaussée',
	},
	{
		attribut: 'heating_type',
		label: 'Type de chauffage',
	},
	{
		attribut: 'heating_energy',
		label: 'Énergie de chauffage',
	},
	{
		attribut: 'heating_format',
		label: 'Format de chauffage',
	},
	{
		attribut: 'indoor_parking',
		label: 'Parking intérieur',
	},
	{
		attribut: 'outdoor_parking',
		label: 'Parking extérieur',
	},
	{
		attribut: 'air_conditionning',
		label: 'Climatisation',
	},
	{
		attribut: 'visiophone',
		label: 'Visiophone',
	},
	{
		attribut: 'acces_handicapes',
		label: 'Accès handicapé',
	},

	{
		attribut: 'formatResidence',
		label: 'Format de residence',
	},
	{
		attribut: 'water_alimentation',
		label: 'Alimentation en eau',
	},
	{
		attribut: 'typeResidence',
		label: 'Type de residence',
	},
	{
		attribut: 'digicode',
		label: 'Digicode',
	},
	{
		attribut: 'alarme',
		label: 'Alarme',
	},
];

export const isValidJSON = (str) => {
	try {
		JSON.parse(str);
		return true;
	} catch (e) {
		return false;
	}
};

export const defaultValue = (value) => {
	return value;
};

export const defaultValueOn = (field) => {
	return document.querySelector(`[name=${field}]`)?.value;
};
