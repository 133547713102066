export const TARGET = {
	SINGLE: 'single person',
	COUPLE: 'couple',
	CORPORATION: 'corporation'
};
export const CIVILITY = {
	sir: {
		label: 'Mr'
	},
	madam: {
		label: 'Mme'
	},
	'sir/madam': {
		label: 'Mr et Mme'
	},
	'sir/sir': {
		label: 'Mr et Mr'
	},
	'madam/madam': {
		label: 'Mme et Mme'
	}
};

export const PhoneNumberDisplay = ({ contact }) => {
	if (contact.target === TARGET.SINGLE) {
		return ` ${contact.phone}`;
	} else if (contact.target === TARGET.COUPLE) {
		return ` ${contact.man_info.phone} / ${contact.woman_info.phone} `;
	}
};

export const EmailDisplay = ({ contact }) => {
	if (contact.target === TARGET.SINGLE) {
		return ` ${contact.mail}`;
	} else if (contact.target === TARGET.COUPLE) {
		return ` ${contact.man_info.mail} / ${contact.woman_info.mail} `;
	}
};

export const ContactDisplayName = ({ contact }) => {
	let result = CIVILITY[contact.civility]?.label ?? '';

	if (contact.target === TARGET.SINGLE) {
		return result + ` ${contact.firstname} ${contact.lastname}`;
	} else if (contact.target === TARGET.COUPLE) {
		return (
			result +
			` ${contact.man_info.firstname} ${contact.man_info.lastname} ` +
			` ${contact.woman_info.firstname} ${contact.woman_info.lastname}`
		);
	}
};

export const CountryAdressDisplay = ({ contact }) => {
	if (contact.target === TARGET.SINGLE) {
		return ` ${contact.country_contact}`;
	} else if (contact.target === TARGET.COUPLE) {
		return ` ${contact.man_info_compl.country} / ${contact.woman_info_compl.country} `;
	}
};

export const ContactAdressDisplay = ({ contact }) => {
	if (contact.target === TARGET.SINGLE) {
		return ` ${contact.adress_contact}`;
	} else if (contact.target === TARGET.COUPLE) {
		return ` ${contact.man_info_compl.adress} / ${contact.woman_info_compl.adress} `;
	}
};
