import { useEffect, useState } from 'react';

export const SelectInput = ({ element, style, register, control }) => {
	const [elementCopy, setElementCopy] = useState();
	const shouldRender = (choice) => {
		let shouldShow = [];
		if (choice.visible) {
			choice.visible.forEach((visible) => {
				// Si c'est un radio, alors mettre le checked dans le querySelector
				let doc = document.querySelector(`[name=${visible.field}]`);
				if (doc?.type === 'radio')
					doc = document.querySelector(`[name=${visible.field}]:checked`);

				// LA CONDITION DE VISIBILITÉ EST FAIT DE SORTE QUE LA VALEUR DU FIELD SOIT EGALE À LA VALEUR DONNÉE
				// TRAITER LES VALEURS AVEC UNE CONDITION AND

				if (visible.condition === 'INEQUAL') {
					shouldShow.push(!visible.value.includes(doc?.value));
				} else {
					shouldShow.push(visible.value.includes(doc?.value));
				}
			});
		}
		return shouldShow.every((val) => val === true);
	};

	useEffect(() => {
		let data = element;
		if (element.onInit)
			element.onInit(element).then((elem) => {
				setElementCopy(elem);
			});
		else setElementCopy(data);
	}, []);
	return (
		elementCopy && (
			<>
				<div className='flex items-center gap-4 w-full'>
					<select
						className={style.simple__input}
						{...register(elementCopy.name, { ...elementCopy.validation })}
						defaultValue={elementCopy.attributes?.defaultValue}>
						{elementCopy.choices.map((choice, index) => {
							return (
								shouldRender(choice) && (
									<option value={choice.value} key={index}>
										{choice.title}
									</option>
								)
							);
						})}
					</select>
				</div>
			</>
		)
	);
};
