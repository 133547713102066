import { useNavigate } from 'react-router-dom';
import { imageBien } from '../../../../helpers/image.source.';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/bundle';
import { useEffect, useState } from 'react';

function ImagesSection({ contact }) {
	const navigate = useNavigate();

	const [preferency, setPreferency] = useState({
		type_offre: contact.preferency.type_offert_id
	});

	useEffect(() => {
		if (contact.preferency.type_estate_id != 7) {
			setPreferency({
				...preferency,
				type_estate: contact.preferency.type_estate_id
			});
		}
	}, []);

	return (
		<div className='p-2'>
			<Swiper
				modules={[Autoplay, Navigation]}
				spaceBetween={50}
				slidesPerView={3}
				autoplay={{
					delay: 2000,
					pauseOnMouseEnter: true
				}}
				navigation={{
					enabled: true
				}}
				loop={true}>
				{contact.rapprochements
					.filter((item) =>
						preferency.type_estate
							? item.bien.type_offert_id == preferency.type_offre &&
							  item.bien.type_estate_id == preferency.type_estate
							: item.bien.type_offert_id == preferency.type_offre
					)
					.sort((a, b) => a.value - b.value)
					.map((rapproche, index) => (
						<SwiperSlide key={index}>
							<div
								className='border-2 border-grey w-full p-2 rounded cursor-pointer min-h-[400px]'
								onClick={() =>
									navigate('/mes-biens/details-bien/' + rapproche.bien.uuid)
								}>
								<div className='w-full p-1'>
									{rapproche.bien.photos &&
										rapproche.bien.photos.photos_couvert.map((image, key) => (
											<img
												key={key}
												className=' w-100 mx-auto object-contain h-[60%] transition duration-300 ease-in-out transform hover:scale-105'
												src={imageBien(
													encodeURI(`photos_couvert${image.photo}`)
												)}
												alt={image.description}
											/>
										))}
								</div>
								<div className='shadow-lg min-h-[150px] p-2 mt-4'>
									<h1 className='text-xl break-words text-center font-bold'>
										{rapproche.bien.advertisement.title}
									</h1>

									<h4 className='text-xl break-words text-center w-1/3 mx-auto p-2 text-white font-bold mt-8 blue-color rounded'>
										{rapproche.value}%
									</h4>
								</div>
							</div>
						</SwiperSlide>
					))}
			</Swiper>
		</div>
	);
}

export default ImagesSection;
