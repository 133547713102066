import React from 'react';

export const Button = ({ children, className, ref, type, ...rest }) => {
	return (
		<button
			className={`${className} text-white px-4 py-2`}
			type={type}
			ref={ref}
			{...rest}>
			{children}
		</button>
	);
};
