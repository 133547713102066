import React from 'react';

export const FooterBlock = React.memo(() => {
	return (
		<div className='blue-color text-white mt-2 px-10'>
			<div className='w-full text-center p-2'>
				<h3 className='font-bold'>MAPIM IMMO</h3>
				<h6>SOLUTION IMMOBILIÈRE</h6>
			</div>
			<div className='w-full p-2'>
				<ul className='flex justify-between items-stretch'>
					<li>
						<a href='mailto:contact@mapimimmo.fr'>NOUS CONTACTER</a>{' '}
					</li>
					<li>-</li>
					<li>
						<a href='https://www.youtube.com/@MAPIMIMMO' target='_blank'>
							TUTORIELS
						</a>
					</li>
					<li>-</li>
					<li>
						SUIVEZ-NOUS
						<div className='flex items-center justify-between my-4'>
							<a
								href='https://www.linkedin.com/company/mapim-immo/'
								target='_blank'>
								<img className='w-6' src='/images/icons/business.png' alt='' />
							</a>
							<a href='https://www.instagram.com/mapim_immo/' target='_blank'>
								<img className='w-6' src='/images/icons/photo.png' alt='' />
							</a>
							<a href='https://www.facebook.com/mapim.immo' target='_blank'>
								<img
									className='w-6'
									src='/images/icons/social-media-2.png'
									alt=''
								/>
							</a>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
});
