import { requiredField } from '../../../validation/validation';
export const Image = [
	{
		//photo original
		type: 'file',
		multiple: false,
		name: 'photos_original',
		tagName: 'photos.photos_couvert',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos original du bien:',
		validation: {
			validate: { requiredField },
		},
	},

	{
		//Photos slide
		type: 'file',
		name: 'photos_slide',
		multiple: true,
		tagName: 'photos.photos_slide1',
		className: 'w-full sm:w-1/2 ',
		title: 'Autres Images',
		validation: {
			validate: { requiredField },
		},
	},
];
