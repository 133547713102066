import { Modal } from '../../commons/modal/modal';

export const NotificationPige = ({ pige, isOpen, handleClose }) => {
	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			allowBackdrop={false}
			height='h-72'
			width='w-1/3'
			allowForceClose={true}
			modalTitle={"Notification à propos d'une pige"}>
			<div className='p-4 text-center text-white text-xl flex items-center justify-center font-normal blue-color'>
				Vous avez une nouvelle notification pour une pige.
				<br />
				Le message : {pige.title}
				<br />
				Date : {pige.reminder}
			</div>
		</Modal>
	);
};
