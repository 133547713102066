import { useEffect, useState } from 'react';

import { Breadcumbs } from '../../../components/generals/headers/breadcumbs';
import { CardImpression } from '../../../components/generals/biens/impression/CardImpression';
import { Modal } from '../../../components/commons/modal/modal';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BienService from '../../../service/bien/BienService';
import { Model } from './models/Model';

const MODELS = [
	// {
	// 	title: 'Modèle 1 - Par défaut',
	// 	name: 'model1',
	// 	disposition: 'landscape'
	// },
	{
		title: "Modèle - Visite d'agent",
		name: 'model2',
		disposition: 'portrait'
	},
	{
		title: 'Modèle - Visite client',
		name: 'model3',
		disposition: 'portrait'
	},
	{
		title: 'Modèle - Fiche vitrine Client',
		name: 'model4',
		disposition: 'landscape'
	},
	{
		title: 'Modèle - Fiche vitrine Agent',
		name: 'model5',
		disposition: 'landscape'
	}
];

export const ImpressionEstatePage = () => {
	const { bienUuid } = useParams();
	const [bien, setBien] = useState();
	useEffect(() => {
		BienService.getBienById(bienUuid).then(({ data }) => setBien(data));
	}, []);

	return (
		bien && (
			<>
				<Breadcumbs title={'Impression du bien'} />
				<div className='p-4'>
					<p></p>
					<div className='w-full flex gap-4 flex-wrap items-stretch justify-center'>
						{MODELS.map((model, index) => (
							<Link
								to={{
									pathname: `/mes-biens/impression-by-model/${model.name}`
								}}
								state={{ bien, model }}
								className='w-full sm:w-1/4 flex items-center justify-center p-2 h-48 uppercase text-center font-bold text-black/60'
								key={index}>
								<CardImpression model={model} />
							</Link>
						))}
					</div>
				</div>
			</>
		)
	);
};
