import { useEffect, useState } from 'react';
import { Model1 } from './Model1';
import { Model2 } from './Model2';
import { Model3 } from './Model3';
import { Model4 } from './Model4';
import { Model5 } from './Model5';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { GeneralSpinner } from '../../../../components/commons/general-spinner';
import { useLocation } from 'react-router-dom';
import { Breadcumbs } from '../../../../components/generals/headers/breadcumbs';
import { createPortal } from 'react-dom';

export const Model = () => {
	const [isDownloading, setIsDownloading] = useState(false);
	const location = useLocation();
	const contentId = 'content-model';
	const { bien, model } = location.state;
	const generatePDF = async () => {
		setIsDownloading(true);

		setTimeout(async () => {
			const element = document.getElementById(contentId);
			if (!element) {
				throw new Error(`Élément avec l'ID ${contentId} non trouvé`);
			}
			const fileName = model.title,
				paperFormat = 'a4',
				orientation = model.disposition;

			const canvas = await html2canvas(element, {
				useCORS: true,
				allowTaint: false,
				scrollY: -window.scrollY,
				windowWidth: element.scrollWidth,
				windowHeight: element.scrollHeight,
				scale: 2 // Meilleure qualité
			});

			const pdf = new jsPDF(orientation, 'mm', paperFormat);
			const pageWidth = pdf.internal.pageSize.getWidth();
			const pageHeight = pdf.internal.pageSize.getHeight();

			const imgWidth = canvas.width;
			const imgHeight = canvas.height;
			const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);
			const imgX = (pageWidth - imgWidth * ratio) / 2;
			const imgY = (pageHeight - imgHeight * ratio) / 2;

			const imgData = canvas.toDataURL('image/png');
			pdf.addImage(imgData, 'PNG', 0, 0, pageWidth, pageHeight);

			// Sauvegarder le PDF
			pdf.save(`${fileName}.pdf`);
			setIsDownloading(false);
		}, 2000);
	};

	useEffect(() => {}, []);

	return (
		<>
			<Breadcumbs title={"Modèle d'impression"} />
			<div className='mb-8'>
				<button
					className='blue-color text-white uppercase font-bold px-4 py-2 rounded-xl'
					onClick={generatePDF}>
					Imprimer
				</button>
			</div>

			<div id='content-model' className='p-5 text-2xl'>
				{model.name === 'model1' && (
					<Model1 bien={bien} is_downloading={isDownloading} />
				)}
				{model.name === 'model2' && (
					<Model2 bien={bien} is_downloading={isDownloading} />
				)}
				{model.name === 'model3' && (
					<Model3 bien={bien} is_downloading={isDownloading} />
				)}
				{model.name === 'model4' && (
					<Model4 bien={bien} is_downloading={isDownloading} />
				)}
				{model.name === 'model5' && (
					<Model5 bien={bien} is_downloading={isDownloading} />
				)}
			</div>
			{isDownloading && <GeneralSpinner />}
		</>
	);
};
