export const LEGAL_FORM = {
	ei: {
		title: 'EI',
		value: 'ei'
	},
	eurl: {
		title: 'EURL',
		value: 'eurl'
	},
	sarl: {
		title: 'SARL',
		value: 'sarl'
	},
	sasu: {
		title: 'SASU',
		value: 'sasu'
	},
	sas: {
		title: 'SAS',
		value: 'sas'
	},
	sa: {
		title: 'SA',
		value: 'sa'
	},
	snc: {
		title: 'SNC',
		value: 'snc'
	},
	scs: {
		title: 'SCS',
		value: 'scs'
	},
	sca: {
		title: 'SCA',
		value: 'sca'
	},
	autre: {
		title: 'Autre',
		value: 'autre'
	}
};
