import { useEffect, useState } from 'react';
import { Breadcumbs } from '../../components/generals/headers/breadcumbs';
import { AlertService } from '../../service/models/alert';
import { Modal } from '../../components/commons/modal/modal';
import { successToast } from '../../service/toast/showToast';
import { GeneralSpinner } from '../../components/commons/general-spinner';
import { AlertSection } from './Alerts/AlertSection';
import { Pagination } from '../../components/commons/pagination';

export const AlertePage = () => {
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [alerts, setAlerts] = useState([]);
	const [page, setPage] = useState(1);
	const [alert, setAlert] = useState({
		type: 'vente',
		bien: 'appartement'
	});

	const handleChangePage = (page) => setPage(page);

	const createAlert = () => {
		setLoading(true);
		AlertService.createAlert(alert).then(({ data }) => {
			successToast("Enregistrement de l'alerte réussi");
			setAlert({
				type: 'vente',
				bien: 'appartement',
				cp: ''
			});
			setShowModal(false);
			fetchAlerts();
		});
	};

	const removeAlert = (alert) => {
		AlertService.removeAlert(alert).then(() => {
			successToast('Alerte supprimée');
			fetchAlerts();
		});
	};

	const fetchAlerts = () => {
		setLoading(true);
		AlertService.getAlerts(page)
			.then(({ data }) => {
				setAlerts(data.data);
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		fetchAlerts();
	}, [page]);

	return (
		alerts.data && (
			<>
				{loading && <GeneralSpinner />}

				<div className='flex items-center justify-between mb-20'>
					<Breadcumbs title={'Annonces enregistrées'} />
					<button
						className='default-color py-2 px-4 text-white font-medium rounded-md'
						onClick={() => setShowModal(true)}>
						AJOUTER UNE ALERTE
					</button>
					<Modal
						isOpen={showModal}
						height='h-auto'
						modalTitle={'Enregistrer une nouvelle alerte'}
						allowForceClose={true}
						allowBackdrop={false}
						handleClose={() => setShowModal(false)}>
						<div className='p-4 border-2 border-solid font-bold'>
							<div className='flex gap-4 items-center justify-between my-2'>
								<label htmlFor='type' className='w-1/4'>
									Type ( * ) :
								</label>
								<select
									className='w-3/4 p-4'
									id='type'
									defaultValue={alert.type}
									onChange={(e) =>
										setAlert({ ...alert, type: e.target.value })
									}>
									<option value='vente'>Vente</option>
									<option value='location'>Location</option>
								</select>
							</div>
							<div className='flex gap-4 items-center justify-between my-2'>
								<label htmlFor='bien' className='w-1/4'>
									Bien ( * ) :
								</label>
								<select
									className='w-3/4 p-4'
									defaultValue={alert.bien}
									id='bien'
									onChange={(e) =>
										setAlert({ ...alert, bien: e.target.value })
									}>
									<option value='appartement'>Appartement</option>
									<option value='chambre'>Chambre</option>
									<option value='maison'>Maison</option>
									<option value='terrain'>Terrain</option>
								</select>
							</div>

							<div className='flex gap-4 items-center justify-between my-2 flex-wrap'>
								<label htmlFor='cp' className='w-1/5'>
									Code postal ( * ) :
								</label>
								<input
									type='text'
									className='w-full p-4 border-solid border'
									defaultValue={alert.cp}
									onChange={(e) =>
										setAlert({
											...alert,
											cp: JSON.stringify(e.target.value.split(','))
										})
									}
								/>
								<small className='block w-full text-center'>
									Separé par une virgule
								</small>
							</div>

							<div className='flex gap-4 items-center my-2 flex-wrap justify-center'>
								<div className='flex items-center gap-4'>
									<label htmlFor=''>Max surface :</label>
									<input
										type='number'
										className='p-1 1/5 border border-solid'
										defaultValue={alert.max_surf}
										min={0}
										onChange={(e) =>
											setAlert({ ...alert, max_surf: e.target.value })
										}
									/>
								</div>
								<div className='flex items-center gap-4'>
									<label htmlFor=''>Min surface :</label>
									<input
										type='number'
										defaultValue={alert.min_surf}
										className='p-1 1/5 border border-solid'
										min={0}
										onChange={(e) =>
											setAlert({ ...alert, min_surf: e.target.value })
										}
									/>
								</div>
								<div className='flex items-center gap-4'>
									<label htmlFor=''>Max pièces :</label>
									<input
										type='number'
										className='p-1 1/5 border border-solid'
										defaultValue={alert.max_pieces}
										min={0}
										onChange={(e) =>
											setAlert({ ...alert, max_pieces: e.target.value })
										}
									/>
								</div>
								<div className='flex items-center gap-4'>
									<label htmlFor=''>Min pièces :</label>
									<input
										type='number'
										defaultValue={alert.min_pieces}
										className='p-1 1/5 border border-solid'
										min={0}
										onChange={(e) =>
											setAlert({ ...alert, min_pieces: e.target.value })
										}
									/>
								</div>
							</div>

							<button
								onClick={createAlert}
								className='blue-color text-white p-4 rounded'>
								ENREGISTRER
							</button>
						</div>
					</Modal>
				</div>
				{alerts.data.map((item, index) => (
					<AlertSection alert={item} key={index} removeAlert={removeAlert} />
				))}
				{alerts.data.length != 0 && alerts.links?.length != 0 && (
					<div className='h-12 mt-10 flex flex-wrap w-full gap-1 justify-center items-center'>
						{alerts.links
							.slice(1, alerts.links.length)
							.slice(0, -1)
							.map((link, index) => {
								return (
									<Pagination
										changePage={handleChangePage}
										key={index}
										link={link}></Pagination>
								);
							})}
					</div>
				)}
			</>
		)
	);
};
