import {
	coverImageFromProxy,
	imageBackend,
	imageFromProxy,
	slideImageFromProxy
} from '../../../../helpers/image.source.';
import { getStorageUser } from '../../../../service/localStorage';
import { Dpe } from '../dpe/Dpe';
import { Pricing } from '../dpe/Pricing';

export const Model2 = ({ bien, is_downloading }) => {
	const choice = ['Non précisé', 'Non', 'Oui'];
	return (
		<>
			<div className='flex justify-end w-full mb-8'>
				<img
					src={
						getStorageUser().user.agency.agency_logo
							? imageFromProxy(getStorageUser().user.agency.agency_logo)
							: '/images/LOGO_MAPIM_IMMO_BLANC.jpg'
					}
					className='w-[200px] h-[100px]'
					alt='Logo of society'
				/>
			</div>

			<div className='flex justify-between flex-wrap'>
				<div className='w-1/4'>
					<b>{bien.advertisement.title}</b>
					<br />
					{bien.zap_country} {bien.name_country && ','} {bien.name_country}
				</div>
				<div className='w-1/3 text-right'>
					<Pricing bien={bien} />
				</div>
			</div>
			<div
				className={`grid grid-cols-3 ${
					bien.photos.photos_slide.length >= 3 ? 'grid-rows-3' : 'grid-rows-2'
				} gap-2 my-4`}>
				<div
					className={`border-2 border-solid ${
						bien.photos.photos_slide.length !== 0
							? 'col-span-2'
							: 'col-span-full'
					}  row-span-2 flex items-center justify-center`}>
					<img
						src={coverImageFromProxy(bien.photos.photos_couvert[0].photo)}
						alt='Cover'
						className={`w-full`}
					/>
				</div>
				{bien.photos.photos_slide &&
					[0, 1, 2, 3, 4].map(
						(item, index) =>
							bien.photos.photos_slide[index] && (
								<div
									className='border-2 border-solid col-span-1 row-span-1 flex items-center justify-center'
									key={index}>
									<img
										src={slideImageFromProxy(
											bien.photos.photos_slide[index].photo
										)}
										alt={'Slide ' + index}
									/>
								</div>
							)
					)}
			</div>

			<div className='flex gap-8'>
				<div className='w-1/2'>
					<h3 className='mb-4'>
						<b>Description :</b>
					</h3>
					<p className='text-[16px]'>{bien.advertisement.description}</p>
				</div>
				<div className='w-1/2'>
					<h3 className='mb-4'>
						<b>Caractéristiques :</b>
					</h3>

					<div className='flex justify-between'>
						<ul className='text-[16px] w-1/2'>
							<li>
								- Surface habitable : {bien.living_area} m<sup>2</sup>
							</li>
							<li>
								- Surface terrain : {bien.land_area} m<sup>2</sup>
							</li>
							<li>
								- Surface séjour : {bien.interior_detail.surfaceStay} m
								<sup>2</sup>
							</li>
							<li>
								- Terrasse : {choice[parseInt(bien.exterior_detail.terrace)]}
							</li>
							<li>
								- Nombre de salle(s) d'eau :{' '}
								{bien.interior_detail.nbOfRoomWater}
							</li>
							<li>- Cuisine : {bien.interior_detail.TypeOfKitchen}</li>
							<li>
								- Résidence : {bien.exterior_detail.formatResidence} -{' '}
								{bien.exterior_detail.typeResidence}
							</li>
						</ul>
						<ul className='text-[16px] w-1/2'>
							<li>
								- Surface habitable : {bien.living_area} m<sup>2</sup>
							</li>
							<li>
								- Surface terrain : {bien.land_area} m<sup>2</sup>
							</li>
							<li>
								- Surface séjour : {bien.interior_detail.surfaceStay} m
								<sup>2</sup>
							</li>
							<li>
								- Terrasse :{' '}
								{bien.exterior_detail.terrace
									? choice[parseInt(bien.exterior_detail.terrace)]
									: ''}
							</li>
							<li>
								- Année de construction : {bien.diagnostic.year_construction}
							</li>
							<li>
								- Estimation coût moy :
								<ul className='list-disc pl-10'>
									<li> {bien.diagnostic.cost_estimate} €</li>
									<li>Année de référence : {bien.diagnostic.ref_year}</li>
								</ul>
							</li>
						</ul>
					</div>
					<div className='w-full flex justify-end items-center mt-20'>
						<Dpe bien={bien} is_downloading={is_downloading} />
					</div>
				</div>
			</div>
		</>
	);
};
