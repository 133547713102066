import { createHttp } from '../../http/index';

const http = createHttp();
export const EstimationService = {
	createEstimation: async (data) => http.post('/estimation', data),
	attributeTo: async (data) => http.post('/estimation/affect', data),
	getEstimations: async (pageNumber, params) => {
		const parameters = Object.entries(params)
			.map(
				([key, value]) =>
					`${encodeURIComponent(key)}=${encodeURIComponent(value)}`
			)
			.join('&');

		return http.get(
			`/estimation${pageNumber ? '?page=' + pageNumber : ''}${'&' + parameters}`
		);
	},
	getCp: async () => http.get(`/estimation/cp`),
	getEstimation: async (estimationUuid) =>
		http.get(`/estimation/detail/${estimationUuid}`),
	sendEstimationToMail: async (estimationUuid) =>
		http.get(`/estimation/send-to-mail/${estimationUuid}`)
};
