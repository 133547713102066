import { requiredField } from '../../../validation/validation';
import { CRITERE, CRITERE_OFFRE, TYPE_BIEN } from '../../type';

export const PlusCriteriaBien = [
	{
		//Nb de lots
		type: 'number',
		title: 'Nombres de lots :',
		className: 'w-1/2',
		name: 'nbOfLots',
		tagName: 'preferency.nbOfLots',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.IMMEUBLE],
				condition: 'EQUAL',
			},
		],
	},
	{
		//Nb de couchages
		type: 'number',
		title: 'Nombres de couchages :',
		className: 'w-1/2',
		name: 'nbOfSleeping',
		tagName: 'preferency.nbOfSleeping',
		// validation: {
		// 	validate: { requiredField },
		// },
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.IMMEUBLE],
				condition: 'EQUAL',
			},
		],
	},
	{
		//Nb de bain
		type: 'number',
		title: 'Nombres de salle(s) des bains :',
		className: 'w-1/2',
		// validation: {
		// 	validate: { requiredField },
		// },
		name: 'nbOfBathroom',
		tagName: 'preferency.nbOfBathroom',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Nb de eau
		type: 'number',
		title: "Nombres de salle(s) d'eau :",
		className: 'w-1/2',
		name: 'nbOfRoomWater',
		tagName: 'preferency.nbOfRoomWater',
		// validation: {
		// 	validate: { requiredField },
		// },
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Nombre de niveaux
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Nombre de niveaux :',
		name: 'number_level',
		tagName: 'preferency.number_level',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.CAVE, CRITERE.GARAGE, CRITERE.PARKING],
				condition: 'INEQUAL',
			},
		],
	},

	{
		//Jardin
		type: 'radio',
		name: 'garden_exist',
		tagName: 'preferency.garden_exist',
		className: 'w-full ',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Jardin :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Surface
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Surface (m2)',
		name: 'garden_exist_area',
		tagName: 'preferency.garden_exist_area',
		visible: [{ condition: 'EQUAL', field: 'garden_exist', value: ['3'] }],
	},
	{
		//Privatif
		type: 'radio',
		name: 'garden_exist_private',
		className: 'w-full ',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Privatif :',
		tagName: 'preferency.garden_exist_private',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [{ condition: 'EQUAL', field: 'garden_exist', value: ['3'] }],
	},
	{
		className: 'w-full sm:w-1/2',
	},
	{
		//Piscine
		type: 'radio',
		name: 'swim',
		className: 'w-full ',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Piscine :',
		tagName: 'preferency.swim',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//STATUT
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'select',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Statut :',
		name: 'swim_exist_statut',
		tagName: 'preferency.swim_exist_statut',
		choices: [
			{
				title: 'Non précisé',
				value: 'unprecised',
			},
			{
				title: 'Privative',
				value: 'private',
			},
			{
				title: 'Collective',
				value: 'collective',
			},
			{
				title: 'Surveillée',
				value: 'survey',
			},
		],
		visible: [{ condition: 'EQUAL', field: 'swim', value: ['3'] }],
	},
	{
		//Nature
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'select',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Nature :',
		name: 'swim_exist_nature',
		tagName: 'preferency.swim_exist_nature',
		choices: [
			{
				title: 'Coque',
				value: 'coque',
			},
			{
				title: 'Traditionnelle',
				value: 'traditinnal',
			},
			{
				title: 'Hors_sol',
				value: 'hors-sol',
			},
			{
				title: 'Semi-entérrée',
				value: 'semi-entérrée',
			},
		],
		visible: [{ condition: 'EQUAL', field: 'swim', value: ['3'] }],
	},

	{
		//Nombre
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 mt-2 ',
		type: 'number',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Nombre de garage :',
		name: 'garage_number',
		tagName: 'preferency.garage_number',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				condition: 'INEQUAL',
				field: CRITERE_OFFRE.formName,
				value: [CRITERE.CAVE, CRITERE.PARKING],
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		visible: [{ field: 'type_offert_id', value: ['1', '4'] }],
	},

	{
		// Parking disponible
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'number',
		title: 'Nombre de Parking  :',
		tagName: 'preferency.parking',
		name: 'parking',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				condition: 'EQUAL',
				field: TYPE_BIEN.formName,
				value: [TYPE_BIEN.AUTRES],
			},
			{
				condition: 'EQUAL',
				field: CRITERE_OFFRE.formName,
				value: [CRITERE.PARKING],
			},
		],
		// validation: {
		// 	validate: { requiredField },
		// },
	},
	{
		type: 'number',
		name: 'number_cave',
		title: 'Nombre de cave',
		tagName: 'preferency.number_cave',
		// validation: {
		// 	validate: { requiredField },
		// },
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				condition: 'EQUAL',
				field: CRITERE_OFFRE.formName,
				value: [CRITERE.CAVE],
			},
		],
	},
	{
		// Parking
		group: 'info_personnelle',
		className: 'w-full sm:w-2/12 md:mt-4 md:pl-4 !justify-start',
		title: 'Parking',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		// Parking Intérieur
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'number',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Parking Intérieur :',
		name: 'indoor_parking',
		tagName: 'preferency.indoor_parking',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},

	{
		// Parking Extèrieur
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'number',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: ' Parking Exterieur :',
		name: 'outdoor_parking',
		tagName: 'preferency.outdoor_parking',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},

	{
		//Situation
		group: 'info_personnelle',
		className: 'w-full sm:w-2/12 !justify-start mt-4 sm:pl-4',

		title: 'Situation',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Exposition
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'select',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Exposition :',
		name: 'exhibition',
		tagName: 'preferency.exhibition',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
		choices: [
			{
				title: 'N',
				value: 'N',
			},
			{
				title: 'NE',
				value: 'NE',
			},
			{
				title: 'E',
				value: 'E',
			},
			{
				title: 'SE',
				value: 'SE',
			},
			{
				title: 'S',
				value: 'S',
			},
			{
				title: 'SO',
				value: 'SO',
			},
			{
				title: 'O',
				value: 'O',
			},
			{
				title: 'NO',
				value: 'NO',
			},
		],
	},
	{
		//Vue
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'select',
		// validation: {
		// 	validate: { requiredField },
		// },
		choices: [
			{
				title: 'Rue',
				value: 'rue',
			},
			{
				title: 'Champs',
				value: 'Champs',
			},
			{
				title: 'Montagne',
				value: 'Montagne',
			},
			{
				title: 'Mer',
				value: 'Mer',
			},
			{
				title: 'Lac',
				value: 'Lac',
			},
			{
				title: 'Dégagée',
				value: 'Dégagée',
			},
			{
				title: 'Rivière',
				value: 'Rivière',
			},
			{
				title: 'Immeubles',
				value: 'Immeubles',
			},
		],
		title: 'Vue :',
		name: 'view',
		tagName: 'preferency.view',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},

	{
		//Grenier/Combles
		type: 'radio',
		name: 'attic',
		tagName: 'preferency.attic',
		className: 'w-full ',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Grenier /Combles:',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Cellier
		type: 'radio',
		name: 'cellar',
		tagName: 'preferency.cellar',
		className: 'w-full ',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Cellier:',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [
					CRITERE.CAVE,
					CRITERE.GARAGE,
					CRITERE.PARKING,
					CRITERE.IMMEUBLE,
				],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Meubles
		type: 'radio',
		name: 'furnished',
		tagName: 'preferency.furnished',
		className: 'w-full ',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Meubles:',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [
					CRITERE.CAVE,
					CRITERE.GARAGE,
					CRITERE.PARKING,
					CRITERE.IMMEUBLE,
				],
				condition: 'INEQUAL',
			},
		],
	},

	{
		//Surface
		group: 'info_personnelle',
		title: 'Surface',
		className: 'w-full sm:w-2/12 !justify-start mt-4 sm:pl-4',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Carrez
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'number',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: '  Carrez :',
		name: 'carrez_area',
		tagName: 'preferency.carrez_area',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Sejour
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'number',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Séjour :',
		name: 'stay_area',
		tagName: 'preferency.stay_area',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [
					CRITERE.CAVE,
					CRITERE.GARAGE,
					CRITERE.PARKING,
					CRITERE.IMMEUBLE,
				],
				condition: 'INEQUAL',
			},
		],
	},

	{
		className: 'w-full',
	},
	{
		//Mitoyenneté
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'select',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Mitoyenneté :',
		name: 'common_ownership',
		tagName: 'preferency.common_ownership',
		choices: [
			{
				title: 'Indépendant',
				value: 'sir',
			},
			{
				title: '1 côté',
				value: 'sirs',
			},
			{
				title: '2 côtés',
				value: 'sirs',
			},
			{
				title: '3 côtés',
				value: 'sirs',
			},
			{
				title: '4 côtés',
				value: 'sirs',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [
					CRITERE.CAVE,
					CRITERE.GARAGE,
					CRITERE.PARKING,
					CRITERE.IMMEUBLE,
				],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Sous sol
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'select',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Sous sol :',
		name: 'basement',
		tagName: 'preferency.basement',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Sans',
				value: '2',
			},
			{
				title: 'Avec',
				value: '4',
			},
			{
				title: 'Aménagé',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},

	{
		//Surface
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Surface (basement):',
		name: 'basement_amenaged',
		tagName: 'preferency.basement_amenaged',
		visible: [
			{ condition: 'EQUAL', field: 'basement', value: ['3'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Cave
		type: 'radio',
		name: 'cave',
		tagName: 'preferency.cave',
		className: 'w-full sm:w-1/2 ',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Cave:',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//EAU
		group: 'info_personnelle',
		className: 'w-full  !justify-start sm:pl-4 underline',
		title: 'Eau :',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'EQUAL',
			},
		],
	},
	{
		//Alimentation
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'select',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Alimentation :',
		name: 'alimentation',
		tagName: 'preferency.alimentation',
		choices: [
			{
				title: 'Non précisé',
				value: 'non_precise',
			},
			{
				title: 'Sans',
				value: 'sans',
			},
			{
				title: 'Individuel',
				value: 'individuel',
			},
			{
				title: 'Collectif',
				value: 'collectif',
			},
			{
				title: 'Puits',
				value: 'puits',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'EQUAL',
			},
		],
	},
	{
		//Surface
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Surface (cave):',
		name: 'cave_area',
		tagName: 'preferency.cave_area',
		visible: [{ condition: 'EQUAL', field: 'cave', value: ['3'] }],
	},
	{
		//Balcon
		type: 'radio',
		name: 'balcony',
		tagName: 'preferency.balcony',
		className: 'w-full sm:w-1/2',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: ' Balcon:',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [
					CRITERE.CAVE,
					CRITERE.GARAGE,
					CRITERE.PARKING,
					CRITERE.IMMEUBLE,
				],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Surface
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Surface (balcon):',
		name: 'balcony_area',
		tagName: 'preferency.balcony_area',
		visible: [
			{ condition: 'EQUAL', field: 'balcony', value: ['3'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Terrasse
		type: 'radio',
		name: 'teracce',
		tagName: 'preferency.teracce',
		className: 'w-full sm:w-1/2',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Terrasse:',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [
					CRITERE.CAVE,
					CRITERE.GARAGE,
					CRITERE.PARKING,
					CRITERE.IMMEUBLE,
				],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Surface
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Surface (terrasse):',
		name: 'teracce_area',
		tagName: 'preferency.teracce_area',
		visible: [{ condition: 'EQUAL', field: 'teracce', value: ['3'] }],
	},
	{
		//Varangue
		type: 'radio',
		name: 'varangue',
		tagName: 'preferency.varangue',
		className: 'w-full sm:w-1/2',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Varangue:',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [
					CRITERE.CAVE,
					CRITERE.GARAGE,
					CRITERE.PARKING,
					CRITERE.IMMEUBLE,
				],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Loggia
		type: 'radio',
		name: 'logia',
		tagName: 'preferency.logia',
		className: 'w-full sm:w-1/2',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Loggia:',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [
					CRITERE.CAVE,
					CRITERE.GARAGE,
					CRITERE.PARKING,
					CRITERE.IMMEUBLE,
				],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Veranda
		type: 'radio',
		name: 'veranda',
		tagName: 'preferency.veranda',
		className: 'w-full sm:w-1/2',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Veranda:',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE, CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},

	{
		//Plain Pied
		type: 'radio',
		name: 'ground_floor',
		tagName: 'preferency.ground_floor',
		className: 'w-full sm:w-1/2',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Plain Pied:',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE, CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Chauffage
		group: 'info_personnelle',
		className: 'w-full  !justify-start sm:pl-4 underline',
		title: 'Chauffage :',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE, CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Format
		group: 'info_personnelle',
		className: 'w-full sm:w-4/12 ',
		type: 'select',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Format :',
		name: 'heating_format',
		tagName: 'preferency.heating_format',
		choices: [
			{
				title: 'Non précisé',
				value: 'Non précisé',
			},
			{
				title: 'Central',
				value: 'Central',
			},
			{
				title: 'Collectif',
				value: 'Collectif',
			},
			{
				title: 'Individuel',
				value: 'Individuel',
			},
			{
				title: 'Urbain',
				value: 'Urbain',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE, , CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Types
		group: 'info_personnelle',
		className: 'w-full sm:w-4/12',
		type: 'select',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Types :',
		name: 'heating_type',
		tagName: 'preferency.heating_type',
		choices: [
			{
				title: 'Non précisé',
				value: 'Non précisé',
			},
			{
				title: 'Air',
				value: 'Air',
			},
			{
				title: 'Chaudière',
				value: 'Chaudière',
			},
			{
				title: 'Cheminée',
				value: 'Cheminée',
			},
			{
				title: 'Convecteur',
				value: 'Convecteur',
			},
			{
				title: 'Poêle',
				value: 'Poêle',
			},
			{
				title: 'Urbain',
				value: 'Urbain',
			},
			{
				title: 'Autre',
				value: 'Autre',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE, CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Energie
		group: 'info_personnelle',
		className: 'w-full sm:w-4/12',
		type: 'select',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Energie:',
		name: 'heating_energy',
		tagName: 'preferency.heating_energy',
		choices: [
			{
				title: 'Non précisé',
				value: '0',
			},
			{
				title: 'Aerothérmie',
				value: '1',
			},
			{
				title: 'Bois',
				value: '0',
			},
			{
				title: 'Charbon',
				value: '0',
			},
			{
				title: 'Climatisation',
				value: '0',
			},
			{
				title: 'Electrique',
				value: '0',
			},
			{
				title: 'Fioul',
				value: '0',
			},
			{
				title: 'Gaz',
				value: '0',
			},
			{
				title: 'gaz de ville',
				value: '0',
			},
			{
				title: 'Géothermie',
				value: '0',
			},
			{
				title: 'Granules',
				value: '0',
			},
			{
				title: 'Mixte',
				value: '0',
			},
			{
				title: 'pompe à chaleur',
				value: '0',
			},
			{
				title: 'Solaire',
				value: '0',
			},
			{
				title: 'Sans',
				value: '0',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE, CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Ascensceur
		type: 'radio',
		name: 'elevator',
		tagName: 'preferency.elevator',
		className: 'w-full sm:w-1/2',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Ascensceur :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE, CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Climatisation
		type: 'radio',
		name: 'air_conditionning',
		tagName: 'preferency.air_conditionning',
		className: 'w-full sm:w-1/2 ',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Climatisation :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE, CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Cheminée
		type: 'radio',
		name: 'fireplace',
		tagName: 'preferency.fireplace',
		className: 'w-full sm:w-1/2 ',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Cheminée :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE, CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Vitrage
		type: 'select',
		name: 'glazing',
		tagName: 'preferency.glazing',
		className: 'w-full sm:w-1/2 ',
		// validation: {
		// 	validate: { requiredField },
		// },
		title: 'Vitrage :',
		choices: [
			{
				title: 'Simple',
				value: 'sir',
			},
			{
				title: 'Double',
				value: 'sirs',
			},
			{
				title: 'Triple',
				value: 'sirs',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE, CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Equipement
		group: 'info_personnelle',
		className: 'w-full  !justify-start sm:pl-4 underline',
		title: 'Equipement :',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE, CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Accés hindicapès
		type: 'radio',
		name: 'acces_handicapes',
		tagName: 'preferency.acces_handicapes',
		// validation: {
		// 	validate: { requiredField },
		// },
		className: 'w-full sm:w-1/2 ',
		title: 'Accès handicapés :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE, CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Local à velos
		type: 'radio',
		name: 'local_velos',
		// validation: {
		// 	validate: { requiredField },
		// },
		className: 'w-full sm:w-1/2 ',
		title: 'Local à vélos :',
		tagName: 'preferency.local_velos',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],

		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Gardien
		type: 'radio',
		name: 'gardien',
		// validation: {
		// 	validate: { requiredField },
		// },
		className: 'w-full sm:w-1/2 ',
		title: 'Gardien :',
		tagName: 'preferency.gardien',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Sécurité
		group: 'info_personnelle',
		className: 'w-full  !justify-start sm:pl-4 underline',
		title: 'Sécurité :',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Porte Blindés
		type: 'radio',
		name: 'porte_blinde',
		// validation: {
		// 	validate: { requiredField },
		// },
		className: 'w-full sm:w-1/2 ',
		title: 'Porte blindée :',
		tagName: 'preferency.porte_blinde',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Interphone
		type: 'radio',
		name: 'interphone',
		// validation: {
		// 	validate: { requiredField },
		// },
		className: 'w-full sm:w-1/2 ',
		title: 'Interphone :',
		tagName: 'preferency.interphone',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE, CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Visiophone
		type: 'radio',
		name: 'visiophone',
		// validation: {
		// 	validate: { requiredField },
		// },
		className: 'w-full sm:w-1/2 ',
		title: 'Visiophone :',
		tagName: 'preferency.visiophone',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE, CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Alarme
		type: 'radio',
		name: 'alarme',
		// validation: {
		// 	validate: { requiredField },
		// },
		className: 'w-full sm:w-1/2 ',
		title: 'Alarme :',
		tagName: 'preferency.alarme',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE, CRITERE.IMMEUBLE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Residence
		group: 'info_personnelle',
		className: 'w-full sm:w-1/6 items-center',
		title: 'Résidence',
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.IMMEUBLE],
				condition: 'EQUAL',
			},
		],
	},
	{
		//Format
		group: 'info_personnelle',
		className: 'w-full sm:w-2/6 ',
		type: 'select',
		title: 'Format :',
		name: 'formatResidence',
		tagName: 'preferency.formatResidence',
		choices: [
			{
				title: 'Non Précisé',
				value: 'Non Précisé',
			},
			{
				title: 'Ouverte',
				value: 'Ouverte',
			},
			{
				title: 'Fermée',
				value: 'Fermée',
			},
			{
				title: 'Sécurisée',
				value: 'Sécurisée',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.IMMEUBLE],
				condition: 'EQUAL',
			},
		],
	},
	{
		//Type
		group: 'info_personnelle',
		className: 'w-full sm:w-2/6',
		type: 'select',
		title: 'Type :',
		name: 'typeResidence',
		tagName: 'preferency.typeResidence',
		choices: [
			{
				title: 'Non Précisé',
				value: 'Non Précisé',
			},
			{
				title: 'Ancien',
				value: 'Ancien',
			},
			{
				title: 'Neuf',
				value: 'Neuf',
			},
			{
				title: 'Standing',
				value: 'Standing',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.IMMEUBLE],
				condition: 'EQUAL',
			},
		],
	},
];
