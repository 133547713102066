import { useEffect } from 'react';
import { useState } from 'react';
import style from './dpe.module.css';

const DPE_COLOR = {
	A: { class: 'mt-[4%]', name: 'A', downloading_class: 'mt-[6%]' },
	B: { class: 'mt-[8.5%]', name: 'B', downloading_class: 'mt-[11%]' },
	C: { class: 'pt-[15.5%]', name: 'C', downloading_class: 'pt-[18%]' },
	D: { class: 'mt-[23.5%]', name: 'D', downloading_class: 'mt-[27%]' },
	E: { class: 'mt-[30.5%]', name: 'E', downloading_class: 'mt-[34%]' },
	F: { class: 'mt-[40%]', name: 'F', downloading_class: 'mt-[42%]' },
	G: { class: 'mt-[47.5%]', name: 'G', downloading_class: 'mt-[50%]' }
};

export const DpeSmall = ({ bien, is_downloading }) => {
	const calculateDPE = () => {
		const consommation = parseInt(bien.diagnostic.dpe_consommation);
		const ges = parseInt(bien.diagnostic.dpe_ges);

		if (0 <= ges && 6 >= ges && consommation <= 69 && 0 <= consommation) {
			return DPE_COLOR.A;
		}
		if (7 <= ges && 10 >= ges && consommation <= 109 && 70 <= consommation) {
			return DPE_COLOR.B;
		}
		if (11 <= ges && 29 >= ges && consommation <= 179 && 110 <= consommation) {
			return DPE_COLOR.C;
		}
		if (30 <= ges && 49 >= ges && consommation <= 249 && 180 <= consommation) {
			return DPE_COLOR.D;
		}
		if (50 <= ges && 69 >= ges && consommation <= 329 && 250 <= consommation) {
			return DPE_COLOR.E;
		}
		if (70 <= ges && 99 >= ges && consommation <= 429 && 330 <= consommation) {
			return DPE_COLOR.F;
		}
		if (100 <= ges && 430 <= consommation) {
			return DPE_COLOR.G;
		}
	};

	const [currentGes, setCurrentGes] = useState();

	useEffect(() => {
		const ges = calculateDPE();
		setCurrentGes(ges);
	}, []);

	return (
		<>
			{(bien.diagnostic.dpes === 'DPE Vièrge' && (
				<img
					className='w-1/3'
					src='/images/logo_DPE_vierge.png'
					alt='DPE Vierge'
				/>
			)) ||
				(currentGes && (
					<>
						{(is_downloading && (
							<div
								className='w-full flex items-stretch min-h-[130px]'
								style={{
									backgroundSize: '100%',
									backgroundPosition: '0px 0',
									backgroundRepeat: 'no-repeat',
									backgroundImage: `url('/images/DPE/${currentGes.name}.png')`
								}}>
								<div
									className={`${currentGes.class} pl-3 flex gap-[1.5em] items-start text-sm`}>
									<div>{bien.diagnostic.dpe_consommation}</div>
									<div>{bien.diagnostic.dpe_ges}</div>
								</div>
							</div>
						)) || (
							<div
								className='w-full flex items-stretch min-h-[130px]'
								style={{
									backgroundSize: '100%',
									backgroundPosition: '0px 0',
									backgroundRepeat: 'no-repeat',
									backgroundImage: `url('/images/DPE/${currentGes.name}.png')`
								}}>
								<div
									className={`${currentGes.downloading_class} pl-3 flex gap-[2em] items-start text-sm`}>
									<div>{bien.diagnostic.dpe_consommation}</div>
									<div>{bien.diagnostic.dpe_ges}</div>
								</div>
							</div>
						)}
					</>
				))}
		</>
	);
};
