import { IS_APPART } from '../../../../helpers/forms/type';
import {
	coverImageFromProxy,
	imageFromProxy,
	slideImageFromProxy
} from '../../../../helpers/image.source.';
import { getStorageUser } from '../../../../service/localStorage';
import { Dpe } from '../dpe/Dpe';
import { Pricing } from '../dpe/Pricing';
import { useEffect } from 'react';
import { useState } from 'react';

export const Model5 = ({ bien, is_downloading }) => {
	return (
		<div className={`w-full`}>
			<div className='flex justify-between mt-10'>
				<div className='w-full font-bold'>
					{bien.advertisement.title} - {bien.sector.public_country} (
					{bien.sector.public_zap})
				</div>
				<div className='w-1/4 text-right'>
					<Pricing bien={bien} />
				</div>
			</div>
			<div className='grid grid-cols-4 grid-rows-3 gap-2 my-4'>
				<div
					className={`col-span-3 row-span-2 flex items-center relative  ${
						is_downloading && 'mr-44'
					}`}>
					<div className='w-full bg-white/70 p-1 flex items-start gap-8 flex-wrap justify-between absolute bottom-0 left-0 pt-6'>
						<div className='flex flex-wrap items-center gap-8 ml-4'>
							<small className='text-xl font-bold rounded flex gap-1 items-center justify-center'>
								<img className='w-10' src='/images/impressions/measure.png' />
								{bien.land_area} m<sup>2</sup>
							</small>
							{IS_APPART.includes(
								bien.classification_estate.designation.toLowerCase()
							) && (
								<>
									<small className='text-xl font-bold rounded flex gap-1 items-center justify-center'>
										<img
											className='w-10'
											src='/images/impressions/king-size.png'
										/>
										{bien.number_bedroom}
									</small>
									{bien.interior_detail?.nbOfBathroom && (
										<small className='text-xl font-bold rounded flex gap-1 items-center justify-center'>
											<img
												className='w-10'
												src='/images/impressions/shower.png'
											/>
											{bien.interior_detail.nbOfBathroom}
										</small>
									)}
								</>
							)}
						</div>

						<div className='w-1/5 flex items-center justify-center mt-4'>
							<Dpe5 bien={bien} is_downloading={is_downloading} />
						</div>
					</div>
					<img
						src={coverImageFromProxy(bien.photos.photos_couvert[0].photo)}
						alt='Cover'
						className={`w-full h-full object-cover} `}
					/>
				</div>
				<div
					className={`flex flex-col justify-between text-md mt-10 col-span-1 ${
						bien.photos.photos_slide && bien.photos.photos_slide.length != 0
							? 'row-span-2'
							: 'row-span-2'
					}`}>
					<div className='w-full pl-12 break-words text-[16px] text-justify'>
						{bien.advertisement.description}
					</div>
				</div>

				<div
					className={`col-span-3 row-span-1 gap-4 flex items-center justify-start ${
						is_downloading && 'mr-44'
					}`}>
					{bien.photos.photos_slide &&
						bien.photos.photos_slide.length != 0 &&
						[0, 1, 2].map((item, index) => (
							<div key={index} className='w-1/3'>
								{bien.photos.photos_slide[index] && (
									<img
										src={slideImageFromProxy(
											bien.photos.photos_slide[index].photo
										)}
										alt={'Slide ' + index}
										className={`object-cover ${'w-full h-full'}`}
									/>
								)}
							</div>
						))}
				</div>

				<div className='col-span-1 row-span-1 flex items-center'>
					<div>
						<img
							src={
								getStorageUser().user.agency.agency_logo
									? imageFromProxy(getStorageUser().user.agency.agency_logo)
									: '/images/LOGO_MAPIM_IMMO_BLANC.jpg'
							}
							className='w-1/2 mx-auto'
							alt='Model1 du bien'
						/>
						<div className='text-center text-[14px]'>
							<small>{getStorageUser().user.name}</small>
							<br />
							<small>{getStorageUser().user.email}</small>
							<br />
							<small>{getStorageUser().user.phone}</small>
							<br />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const DPE_COLOR = {
	A: { class: 'mt-[0%]', name: 'A', downloading_class: 'mt-[5%]' },
	B: { class: 'mt-[3.5%]', name: 'B', downloading_class: 'mt-[9%]' },
	C: { class: 'pt-[10.5%]', name: 'C', downloading_class: 'pt-[16%]' },
	D: { class: 'mt-[16.5%]', name: 'D', downloading_class: 'mt-[25%]' },
	E: { class: 'mt-[25.5%]', name: 'E', downloading_class: 'mt-[32%]' },
	F: { class: 'mt-[32%]', name: 'F', downloading_class: 'mt-[40%]' },
	G: { class: 'mt-[40.5%]', name: 'G', downloading_class: 'mt-[48%]' }
};

const Dpe5 = ({ bien, is_downloading }) => {
	const calculateDPE = () => {
		const consommation = parseInt(bien.diagnostic.dpe_consommation);
		const ges = parseInt(bien.diagnostic.dpe_ges);

		if (0 <= ges && 6 >= ges && consommation <= 69 && 0 <= consommation) {
			return DPE_COLOR.A;
		}
		if (7 <= ges && 10 >= ges && consommation <= 109 && 70 <= consommation) {
			return DPE_COLOR.B;
		}
		if (11 <= ges && 29 >= ges && consommation <= 179 && 110 <= consommation) {
			return DPE_COLOR.C;
		}
		if (30 <= ges && 49 >= ges && consommation <= 249 && 180 <= consommation) {
			return DPE_COLOR.D;
		}
		if (50 <= ges && 69 >= ges && consommation <= 329 && 250 <= consommation) {
			return DPE_COLOR.E;
		}
		if (70 <= ges && 99 >= ges && consommation <= 429 && 330 <= consommation) {
			return DPE_COLOR.F;
		}
		if (100 <= ges && 430 <= consommation) {
			return DPE_COLOR.G;
		}
	};

	const [currentGes, setCurrentGes] = useState();

	useEffect(() => {
		const ges = calculateDPE();
		setCurrentGes(ges);
	}, []);

	return (
		<>
			{(bien.diagnostic.dpes === 'DPE Vièrge' && (
				<img
					className='w-1/3'
					src='/images/logo_DPE_vierge.png'
					alt='DPE Vierge'
				/>
			)) ||
				(currentGes && (
					<>
						{(is_downloading && (
							<div
								className='w-full flex items-stretch min-h-[110px]'
								style={{
									backgroundSize: '100%',
									backgroundPosition: '0px 0',
									backgroundRepeat: 'no-repeat',
									objectFit: 'cover',
									backgroundImage: `url('/images/DPE/${currentGes.name}.png')`
								}}>
								<div
									className={`${currentGes.class} pl-3 flex gap-[0.7em] items-start text-[10px]`}>
									<div>{bien.diagnostic.dpe_consommation}</div>
									<div>{bien.diagnostic.dpe_ges}</div>
								</div>
							</div>
						)) || (
							<div
								className='w-full flex items-stretch min-h-[130px]'
								style={{
									backgroundSize: '100%',
									backgroundPosition: '0px 0',
									backgroundRepeat: 'no-repeat',
									backgroundImage: `url('/images/DPE/${currentGes.name}.png')`
								}}>
								<div
									className={`${currentGes.downloading_class} pl-3 flex gap-[1em] items-start text-sm`}>
									<div>{bien.diagnostic.dpe_consommation}</div>
									<div>{bien.diagnostic.dpe_ges}</div>
								</div>
							</div>
						)}
					</>
				))}
		</>
	);
};
