import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { MainRouter } from './router';
import { Toaster } from 'react-hot-toast';
import 'survey-core/defaultV2.min.css';
import 'survey-core/i18n/french';
import Pusher from 'pusher-js';
import { getStorageUser } from './service/localStorage';
import { NotificationStep } from './components/generals/suivi/NotificationStep';
import { NotificationPige } from './components/generals/pige/NotificationPige';

const App = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenPige, setIsOpenPige] = useState(false);
	const [step, setStep] = useState();
	const [pige, setPige] = useState();

	useEffect(() => {
		var pusher = new Pusher('4a993c974f24c4047378', {
			cluster: 'mt1'
		});

		const channel = pusher.subscribe('notification');
		channel.bind('incomplete', function (data) {
			const newStepNotification = data.step;
			if (
				newStepNotification?.folder.bien.agent.id == getStorageUser().user.id
			) {
				setIsOpen(true);
				setStep(data.step);
			}
		});

		channel.bind('pige', function (data) {
			const newPigeNotif = data.pigeRemind[0];
			if (newPigeNotif?.agent_id == getStorageUser().user.id) {
				setIsOpenPige(true);
				setPige(newPigeNotif);
			}
		});
	}, [step]);

	return (
		<div className='App'>
			<HelmetProvider>
				<BrowserRouter>
					<MainRouter />
				</BrowserRouter>
			</HelmetProvider>
			<Toaster
				toastOptions={{
					duration: 2000,
					position: 'top-right'
				}}
			/>
			{isOpen && step && (
				<NotificationStep
					isOpen={isOpen}
					handleClose={() => {
						setStep(null);
						setIsOpen(false);
					}}
					step={step}
				/>
			)}
			{isOpenPige && pige && (
				<NotificationPige
					isOpen={isOpenPige}
					handleClose={() => {
						setPige(null);
						setIsOpenPige(false);
					}}
					pige={pige}
				/>
			)}
		</div>
	);
};

export default App;
