import { useMandate } from '../../../service/mandate/useMandate';
import { defaultValue } from '../../attributes';
import { CONDITION, FIELD_TYPE } from '../../input';
import { requiredField, requiredIf } from '../../validation/validation';

const mandatOptions = async () => {
	try {
		const isAuthUrl = [
			'register',
			'login',
			'reset-password',
			'reset-password-pass',
			'two-factor'
		].includes(
			window.location.href.split('/')[
				window.location.href.split('/').length - 1
			]
		);

		if (!isAuthUrl) {
			const response = await useMandate.allMandate();
			const mandats = response.data;

			return mandats.map((mandat, index) => ({
				title: mandat.num_mandat,
				value: mandat.bien_id_bien
			}));
		}
	} catch (error) {
		console.log(error);
	}
};

export const Mandat = [
	{
		title: 'Mandat',
		name: 'mandat_edition',
		type: 'radio',
		tagName: 'mandate.mandat_edition',
		attributes: {
			defaultValue: defaultValue('new')
		},
		validation: {
			validate: {
				requiredField: (value) =>
					requiredIf(value, [
						{
							field: 'contact_type',
							fieldType: FIELD_TYPE.RADIO,
							value: 'owner',
							condition: CONDITION.EQUAL,
							displayed: {
								field: 'Type de contact',
								value: 'Propriétaire'
							}
						}
					])
			}
		},
		choices: [
			{
				title: 'Nouveau mandat',
				value: 'new'
			},
			{
				title: 'Mandat existant',
				value: 'exist'
			}
		]
	},
	{
		//Ajouter  une mandat
		className: 'w-full sm:w-1/2',
		title: 'Associer à un mandat',
		type: 'select',
		attributes: {
			defaultValue: '0'
		},
		name: 'bien_id_bien',
		onInit: async (element) => {
			element.choices = await mandatOptions();
			return element;
		},
		validation: {
			validate: {
				requiredField
			}
		},
		tagName: 'mandate.bien_id_bien',
		visible: [
			{
				field: 'mandat_edition',
				value: ['exist'],
				condition: 'EQUAL'
			}
		]
	},
	{
		//ajouter un mandat
		group: 'info_personnelle',
		type: 'number',
		name: 'num_mandat',
		className: 'w-full sm:w-1/2',
		tagName: 'mandate.num_mandat',
		title: 'Ajouter un mandat:',
		validation: {
			validate: {
				requiredField
			}
		},
		visible: [
			{
				field: 'mandat_edition',
				value: ['new'],
				condition: 'EQUAL'
			}
		]
	},
	{
		//choix
		type: 'select',
		name: 'person',
		className: 'w-full sm:w-1/2 ',
		tagName: 'mandate.person',
		title: 'Choix:',
		choices: [
			{
				title: 'Une personne seule',
				value: 'Une personne seule'
			},
			{
				title: 'Un couple',
				value: 'Un couple'
			},
			{
				title: 'Une personne morale',
				value: 'Une personne morale'
			}
		],
		visible: [
			{
				field: 'mandat_edition',
				value: ['new'],
				condition: 'EQUAL'
			}
		],
		validation: {
			validate: {
				requiredField
			}
		}
	}
];
