import { requiredField } from '../../../validation/validation';
export const InformationCopropriete = [
	{
		//Bien en copropriété
		group: 'bien',
		validation: {
			validate: { requiredField }
		},
		className: 'w-full ',
		type: 'radio',
		title: 'Bien en copropriété :',
		name: 'property_copropriete',
		tagName: 'info_copropriete.property_copropriete.property_copropriete',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_estate_id',
				value: ['1', '2', '3', '4']
			}
		]
	},
	{
		//Lots
		group: 'info_personnelle',
		className: 'w-full sm:w-2/12 !justify-start mt-4 sm:pl-4',
		title: 'Lots',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'property_copropriete', value: ['3'] }
		]
	},
	{
		//N° de lots
		group: 'info_personnelle',
		validation: {
			validate: { requiredField }
		},
		className: 'w-full sm:w-5/12 ',
		type: 'text',
		title: 'N° de lots :',
		name: 'lot_number',
		tagName: 'info_copropriete.lot_number',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'property_copropriete', value: ['3'] }
		]
	},
	{
		//Quote part
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		validation: {
			validate: { requiredField }
		},
		type: 'text',
		title: 'Quote part annuelle charges :',
		name: 'annual_charges',
		tagName: 'info_copropriete.annual_charges',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'property_copropriete', value: ['3'] }
		]
	},
	{
		//MONTANT FONDS DE TRAVAUX:
		group: 'info_personnelle',
		validation: {
			validate: { requiredField }
		},
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Montant fonds de travaux :',
		name: 'amount_fund',
		tagName: 'info_copropriete.amount_fund',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'property_copropriete', value: ['3'] }
		]
	},
	{
		//Milliemes de copropriete
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		validation: {
			validate: { requiredField }
		},
		type: 'text',
		title: 'Millièmes de copropriété :',
		name: 'thousands_copropriete',
		tagName: 'info_copropriete.thousands_copropriete',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'property_copropriete', value: ['3'] }
		]
	},
	{
		//Plan de sauvegarde
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		validation: {
			validate: { requiredField }
		},
		type: 'radio',
		title: 'Plan de sauvegarde :',
		name: 'backupPlan',
		tagName: 'info_copropriete.backupPlan',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'OUi',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'property_copropriete', value: ['3'] }
		]
	},
	{
		//Statut du syndic
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		validation: {
			validate: { requiredField }
		},
		type: 'select',
		title: ' Statut du syndic:',
		name: 'status_syndicat',
		tagName: 'info_copropriete.property_copropriete.status_syndicat',
		choices: [
			{
				title: 'Non précisé',
				value: 'Non précisé'
			},
			{
				title: "Soumis à une procedure d'alerte",
				value: "Soumis à une procedure d'alerte"
			},
			{
				title: 'Soumis à une procedure de redressement ',
				value: 'Soumis à une procedure de redressement '
			},
			{
				title: 'Pas de procedure en cours ',
				value: 'Pas de procedure en cours'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'property_copropriete', value: ['3'] }
		]
	}
];
