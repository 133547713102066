import { useContact } from '../../../../service/contact/useContact';
import { requiredField, greaterThanZero } from '../../../validation/validation';
import { useMandate } from '../../../../service/mandate/useMandate';
import { optionsContacts } from '../../../optionsContact';
import { defaultValueOn } from '../../../attributes';

const contactOptions = async () => {
	try {
		const isAuthUrl = [
			'register',
			'login',
			'reset-password',
			'reset-password-pass',
			'two-factor',
		].includes(
			window.location.href.split('/')[
				window.location.href.split('/').length - 1
			]
		);

		if (!isAuthUrl) {
			const response = await useContact.contactForBien();
			const contacts = response.data;
			contacts.unshift({
				id_contact: 0,
			});
			return contacts.map((contact, index) => {
				if (index === 0) {
					return { title: 'Choisir un contact', value: contact.id_contact };
				} else {
					return {
						...optionsContacts(contact),
					};
				}
			});
		}
	} catch (error) {
		console.log(error);
	}
};

const checkedNumMandat = async (value) => {
	const data = await useMandate.checkIsNumMandat(value);
	return data.status !== 200 ? 'le numéro mandat a été déjà pris' : true;
};

export const Mandat = [
	{
		title: 'Mandat',
		group: 'mandat',
		name: 'mandat_edition',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],

		type: 'radio',
		validation: {
			validate: {
				requiredField,
			},
		},
		choices: [
			{
				title: 'Nouveau mandat',
				value: 'new',
			},
			{
				title: 'Mandat existant',
				value: 'exist',
			},
		],
	},

	{
		//Ajouter  une mandat
		className: 'w-full sm:w-1/2',
		title: 'Associer un contact',
		type: 'select',
		group: 'mandat',
		name: 'contact_id_contact',
		tagName: 'mandate.contact_id_contact',
		onInit: async (element) => {
			element.choices = await contactOptions();
			return element;
		},

		visible: [
			{
				field: 'mandat_edition',
				value: ['exist'],
				condition: 'EQUAL',
			},
		],
	},
	{
		//Associer un mandat
		group: 'info_personnelle',
		type: 'number',
		name: 'num_mandat',
		tagName: 'mandate.num_mandat',
		className: 'w-full sm:w-1/2',
		title: 'Ajouter un mandat:',
		group: 'mandat',
		validation: {
			validate: { checkedNumMandat },
		},
		attributes: {
			valueDependsOn: () => defaultValueOn('num_folder'),
		},
		visible: [
			{
				field: 'mandat_edition',
				value: ['new'],
				condition: 'EQUAL',
			},
		],
	},

	{
		//choix
		type: 'select',
		name: 'person',
		className: 'w-full sm:w-1/2 ',
		group: 'mandat',
		title: 'Choix:',
		tagName: 'mandate.person',
		choices: [
			{
				title: 'Une personne seule',
				value: 'Une personne seule',
			},
			{
				title: 'Un couple',
				value: 'Un couple',
			},
			{
				title: 'Une personne morale',
				value: 'Une personne morale',
			},
		],

		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{
				field: 'mandat_edition',
				value: ['new'],
				condition: 'EQUAL',
			},
		],
	},
];
