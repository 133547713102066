import { BlocCard } from '../../components/generals/card/bloc';
import { Outlet, useNavigate } from 'react-router-dom';
import { Breadcumbs } from '../../components/generals/headers/breadcumbs';

const BLOC = [
	{
		text: 'Mon réseau',
		link: 'my-network',
	},
	{
		text: 'Biens vendus / loués',
		link: 'sold',
	},
	{
		text: 'Biens archivés',
		link: 'archive',
	},
	{
		text: 'Nouveau bien',
		link: 'new/new',
	},
	{
		text: 'Biens actifs',
		link: 'active',
	},
	{
		text: 'Biens inactifs',
		link: 'inactive',
	},
	{
		text: 'Visite de biens',
		link: 'visite',
	},
];
export const BienPage = () => {
	const navigate = useNavigate();

	const handleNavigate = (link) => {
		navigate(`/mes-biens/${link}`);
	};
	return (
		<>
			<Breadcumbs title={'Mes Biens'} />
			<div className='flex flex-wrap-reverse flex-wrap gap-8 justify-between items-center  border-2 border-solid border-[#2d5c6a] p-10'>
				{BLOC.map((item, index) => (
					<BlocCard
						blocTitle={item.text}
						link={item.link}
						onClick={() => handleNavigate(item.link)}
						key={index}
						className={'h-52 sm:w-1/4 w-3/4'}
					/>
				))}
			</div>
		</>
	);
};
