import { createHttp } from '../../http';

const http = createHttp();

export const useBien = {
	createBien: async (bien) => await http.post('/bien/make', bien),
	updateBien: async (id, data) => await http.post(`/bien/make/${id}`, data),
	updateStatus: async (bienId, status) =>
		await http.post(`/bien/publish/${bienId}`, status),
	sendToMail: async (data) => http.post('/bien/send-to-mail', data)
};
