import { requiredField } from '../../../validation/validation';
export const TypeOffreContact = {
	type: 'radio',
	name: 'type_offert_id',
	className: 'w-full ',
	title: "Type d'offre(s) :",
	validation: {
		validate: {
			requiredField
		}
	},
	tagName: 'preferency.type_offert_id',
	choices: [
		{
			title: 'Achat',
			value: '1'
		},
		{
			title: 'Location',
			value: '2'
		},
		{
			title: 'Location saisonnière',
			value: '3'
		},
		{
			title: 'Pro',
			value: '4'
		}
	]
};
