export const CVG = () => {
	return (
		<div className='px-4 !leading-[50px]'>
			<h2 className='font-bold text-2xl text-center my-8'>
				Conditions générales de vente
			</h2>
			<div className='text-xl text-black/90 mt-20'>
				<h1>
					<b> Préambule</b>
				</h1>

				<p>
					{' '}
					Les conditions générales de vente décrites ci-après détaillent les
					droits et obligations de MAPIM et de son client dans le cadre de la
					vente de ses prestations de services. Toute prestation accomplie par
					MAPIM implique donc l'adhésion sans réserve de l'acheteur aux
					présentes conditions générales de vente.
				</p>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 1 - Principes</h1>

				<p>
					Ces conditions générales concernent les prestations de services
					fournis entre professionnels (prestataire / acheteur). Les présentes
					conditions générales expriment l'intégralité des obligations des
					parties. Elles constituent le socle unique de la relation commerciale
					entre les parties, et, en ce sens, l'acheteur est réputé les accepter
					sans réserve. Les présentes conditions générales de vente prévalent
					sur tout autre document, et notamment sur toutes conditions générales
					d'achat. Elles s'appliquent, sans restriction ni réserve, à tous les
					services rendus par le prestataire auprès des acheteurs de même
					catégorie. Le prestataire et l'acheteur conviennent que les présentes
					conditions générales régissent exclusivement leur relation. Le
					prestataire se réserve le droit de modifier ponctuellement ses
					conditions générales. Elles seront applicables dès leur mise en ligne.
					Si une condition de prestation de services venait à faire défaut, elle
					serait considérée être régie par les usages en vigueur dans le secteur
					de la vente à distance dont les sociétés ont leur siège en France. Les
					présentes conditions générales de vente sont communiquées à tout
					acheteur qui en fait la demande, afin de lui permettre de passer
					commande.
					<br /> Le fournisseur se réserve le droit de déroger à certaines
					clauses des présentes, en fonction des négociations menées avec
					l'acheteur, par l'établissement de conditions de vente particulières.
					Le prestataire peut, en outre, établir des conditions générales de
					vente catégorielles, dérogatoires aux présentes conditions générales
					de vente, en fonction du type d'acheteur considéré, selon des critères
					qui resteront objectifs. Les acheteurs répondant à ces critères se
					verront alors appliquer ces conditions générales de vente
					catégorielles. Les présentes conditions générales de vente sont
					applicables jusqu'au 31 décembre 2025.
				</p>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 2 - Contenu</h1>{' '}
				<p>
					Les présentes conditions générales ont pour objet de définir les
					droits et obligations des parties dans le cadre de la vente en ligne
					des prestations proposées par le prestataire à l'acheteur. Elles
					concernent les services suivants : Gestion de la relation client,
					conception de site web, prestation de formation.
					<br /> Les présentes conditions ne concernent que les prestations
					effectuées en France pour des acheteurs situés sur le territoire
					français. Pour toute prestation effectuée hors de France, ou pour un
					acheteur situé hors de France, il convient de le signaler pour obtenir
					un devis spécifique.
				</p>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 3 - La commande</h1>{' '}
				<p>
					L'acheteur passe sa commande en ligne, à partir du catalogue en ligne
					et au moyen du formulaire qui figure sur le site. Pour que la commande
					soit validée, l'acheteur devra accepter, en cliquant à l'endroit
					indiqué sur le site, les présentes conditions générales. Son
					acceptation entraînera l'envoi d'un mail de confirmation de la part du
					prestataire, conformément aux conditions décrites ci-dessous. Le
					paiement se fait par prélèvement bancaire. Toute commande vaut
					acceptation des prix et descriptions des prestations proposées. Dans
					certains cas, notamment défaut de paiement, adresse erronée ou autre
					problème sur le compte de l'acheteur, le prestataire se réserve le
					droit de bloquer la commande de l'acheteur jusqu'à la résolution du
					problème. En cas d'impossibilité de réalisation de la prestation,
					l'acheteur en sera informé par courrier électronique. L'annulation de
					la commande de cette prestation et son éventuel remboursement seront
					alors effectués, le reste de la commande demeurant ferme et définitif.
					Pour toute question relative au suivi d'une commande, l'acheteur doit
					appeler le 0988194762, Du lundi au vendredi, de 10h à 18h, le samedi
					de 10h à 13h (coût d'un appel local).
				</p>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 4 - Signature électronique</h1>{' '}
				<div>
					La fourniture en ligne des coordonnées bancaires de l'acheteur et la
					validation finale de la commande vaudront preuve de l'accord de
					l'acheteur et vaudront :
					<ul className='pl-4'>
						<li>- exigibilité des sommes dues au titre du bon de commande ;</li>
						<li>
							- signature et acception expresse de toutes les opérations
							effectuées.
						</li>
					</ul>
					En cas d'utilisation frauduleuse des coordonnées bancaires, l'acheteur
					est invité, dès le constat de cette utilisation, à contacter le
					0988194762.
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 5 - Confirmation de commande</h1>
				<p className='mt-4'>
					Les informations contractuelles feront l'objet d'une confirmation par
					voie d'e-mail au plus tard au moment du début d'exécution des
					prestations, à l'adresse indiquée par l'acheteur au sein du bon de
					commande.
				</p>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 6 - Preuve de la transaction</h1>{' '}
				<div>
					Les registres informatisés, conservés dans les systèmes informatiques
					du prestataire dans des conditions raisonnables de sécurité, seront
					considérés comme les preuves des communications, des commandes et des
					paiements intervenus entre les parties. L'archivage des bons de
					commande et des factures est effectué sur un support fiable et durable
					pouvant être produit à titre de preuve.
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>
					Article 7 - Informations sur les prestations
				</h1>{' '}
				<div>
					<p className=''>
						Les prestations régies par les présentes conditions générales sont
						celles qui figurent sur le site internet du prestataire et qui sont
						indiquées comme réalisées par le prestataire ou sous son contrôle.
						Elles sont proposées dans la limite des disponibilités du
						prestataire. Les prestations sont décrites et présentées avec la
						plus grande exactitude possible. Toutefois si des erreurs ou
						omissions ont pu se produire quant à cette présentation, la
						responsabilité du prestataire ne pourrait être engagée.
					</p>
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 8 - Prix</h1>{' '}
				<div>
					<p className=''>
						<b>Calcul</b>
						<br /> Sauf convention contraire, le prix des prestations est établi
						en fonction du nombre et de l'expérience du personnel requis, du
						niveau de compétence et de responsabilité nécessaire ; les taux
						horaires sont révisés périodiquement. Seront également facturés,
						s'il y a lieu, les frais de déplacement, de subsistance et
						d'hébergement engagés pour l'exécution des prestations. La TVA au
						taux en vigueur s'ajoute, le cas échéant, aux honoraires et débours.
						Les retards ou autres problèmes imprévus, dont le prestataire n'a
						pas la maîtrise et qui échappent à son contrôle, peuvent entraîner
						des honoraires supplémentaires susceptibles de faire l'objet d'une
						facturation complémentaire. Le prestataire s'engage à informer
						l'acheteur de ces retards et/ou problèmes dès qu'ils surviendront
						afin de pouvoir en évaluer les conséquences avec lui. Des factures
						seront émises correspondant aux prestations fournies et aux débours
						engagés par provision et au fur et à mesure de leur réalisation.
						Sauf convention contraire, les factures sont émises chaque mois. Le
						règlement des factures est exigible : dès réception.
					</p>
					<p className=''>
						<b>Pénalités de retard</b>
						<br /> Conformément à la loi, tout retard de paiement oblige le
						prestataire à facturer des pénalités de retard. Le taux des
						pénalités de retard est établi sur la base du taux minimum légal ;
						par ailleurs, le débiteur en retard sera tenu de régler au
						prestataire une indemnité forfaitaire pour frais de recouvrement de
						40 Euros ; au surplus, le prestataire sera fondé à suspendre
						l'exécution des prestations jusqu'à règlement complet de la facture
						impayée sans que cette inexécution puisse être considérée comme lui
						étant imputable.
					</p>
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>
					Article 9 - Modalités et délais de paiement
				</h1>{' '}
				<div>
					<p className=''>
						<b>Règlement</b>
						<br /> Le règlement de la commande se fait par prélèvement sur le
						compte bancaire de l'acheteur. L'acheteur doit rentrer ses
						coordonnées bancaires à l'endroit prévu et confirmer cette saisie
						par l'ajout de son RIB informatique en pièce jointe du bon de
						commande. Le prestataire se réserve le droit de suspendre toute
						gestion de commande et toute livraison en cas de refus
						d'autorisation de paiement de la part des organismes officiellement
						accrédités ou en cas de non-paiement. Le prestataire se réserve
						notamment le droit de refuser d'effectuer une livraison ou d'honorer
						une commande émanant d'un acheteur qui n'aurait pas réglé totalement
						ou partiellement une commande précédente ou avec lequel un litige de
						paiement serait en cours d'administration. Le prestataire a mis en
						place une procédure de vérification des commandes destinée à assurer
						qu'aucune personne n'utilise les coordonnées bancaires d'une autre
						personne à son insu. Dans le cadre de cette vérification, il pourra
						être demandé à l'acheteur d'adresser, par fax ou par mail, au
						prestataire une copie d'une pièce d'identité ainsi qu'un
						justificatif de domicile. La commande ne sera alors validée qu'après
						réception et vérification par le prestataire des pièces envoyées.
					</p>
					<p className=''>
						<b>Paiement à terme</b>
						<br /> Le prix est payable en totalité et en un seul versement, dès
						réception. Ce délai sera mentionné sur la facture adressée à
						l'acheteur.
					</p>
					<p className=''>
						<b>Retard de paiement</b>
						<br /> Tout retard de paiement entraînera l'exigibilité immédiate de
						la totalité des sommes dues au prestataire par l'acheteur, sans
						préjudice de toute autre action que le prestataire serait en droit
						d'intenter, à ce titre, à l'encontre de l'acheteur.
					</p>
				</div>
			</div>

			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 10 - Délais d'intervention </h1>{' '}
				<div>
					<p className=''>
						Sauf en cas de force majeure ou lors des périodes de fermeture
						clairement annoncées sur la page d'accueil du site, les délais
						d'intervention seront, dans la limite des disponibilités du
						prestataire, ceux indiqués ci-dessous. Les délais d'exécution
						courent à compter de la date d'enregistrement de la commande
						indiquée sur le mail de confirmation de la commande. Pour les
						prestations réalisées en France Métropole, le délai est de 30 jours
						ouvrables à compter du jour suivant celui où l'acheteur a passé sa
						commande. En cas de retard, la responsabilité du prestataire ne
						pourra être engagée, et ce, pour quelque cause que ce soit. Par
						conséquent, aucune demande d'indemnisation, de quelque nature que ce
						soit, ne pourra être réclamée par l'acheteur. En cas
						d'indisponibilité du prestataire pour réaliser la prestation,
						l'acheteur en sera informé au plus tôt et aura la possibilité
						d'annuler sa commande. L'acheteur aura alors la possibilité de
						demander le remboursement des sommes versées dans les 30 jours au
						plus tard de leur versement.
					</p>
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 11 - Modalités de réalisation</h1>{' '}
				<div>
					<p className=''>
						La réalisation n'est entamée qu'après confirmation du paiement par
						l'organisme bancaire du prestataire. Elle est fournie dans le délai
						prévu sur le bon de commande, à compter de la réception par le
						prestataire du bon de commande. En cas de non-respect des conditions
						de paiement figurant ci-dessus, le vendeur pourra suspendre ou
						annuler la prestation. La prestation est réalisée à l'adresse
						indiquée par l'acheteur sur le bon de commande. L'acheteur devra
						veiller à son exactitude. Tout déplacement en pure perte du
						prestataire à cause d'une adresse erronée ou incomplète sera facturé
						à l'acheteur. L'acheteur peut, à sa demande, obtenir l'envoi d'une
						facture à l'adresse de facturation et non à l'adresse de livraison,
						en validant l'option prévue à cet effet sur le bon de commande.
						L'acheteur veillera à donner accès au prestataire notamment s'il
						n'est pas personnellement présent le jour de la réalisation de la
						prestation. La fin de la prestation donnera lieu à un document de
						fin d'intervention. L'acheteur doit indiquer sur ce document et sous
						forme de réserves manuscrites accompagnées de sa signature toute
						anomalie concernant la prestation ou les conditions de sa
						réalisation. Cette vérification est considérée comme effectuée dès
						lors que l'acheteur, ou une personne autorisée par lui, a signé le
						document de fin d'intervention.
					</p>
				</div>
				<ul className='pl-4'>
					<li>
						- quand l'utilisateur utilise les services de paiement, pour la mise
						en œuvre de ces services, la Plateforme est en relation avec des
						sociétés bancaires et financières tierces avec lesquelles elle a
						passé des contrats ;
					</li>
					<li>
						- lorsque l'utilisateur publie, dans les zones de commentaires
						libres de la Plateforme, des informations accessibles au public ;
					</li>
					<li>
						- quand l'utilisateur autorise le site web d'un tiers à accéder à
						ses données ;
					</li>
					<li>
						- quand la Plateforme recourt aux services de prestataires pour
						fournir l'assistance utilisateurs, la publicité et les services de
						paiement. Ces prestataires disposent d'un accès limité aux données
						de l'utilisateur, dans le cadre de l'exécution de ces prestations,
						et ont l'obligation contractuelle de les utiliser en conformité avec
						les dispositions de la réglementation applicable en matière
						protection des données à caractère personnel ;
					</li>
					<li>
						- si la loi l'exige, la Plateforme peut effectuer la transmission de
						données pour donner suite aux réclamations présentées contre la
						Plateforme et se conformer aux procédures administratives et
						judiciaires.
					</li>
				</ul>
			</div>

			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 12 - Obligations du prestataire</h1>{' '}
				<div>
					<p className=''>
						Les engagements du prestataire constituent une obligation de moyens
						au terme de laquelle les prestations seront exécutées dans le strict
						respect des règles professionnelles en usage ainsi, le cas échéant,
						que conformément aux conditions du contrat. Pour ce faire, le
						prestataire affectera à l'exécution des prestations les
						professionnels dotés des compétences requises pour assurer leur
						réalisation conformément à ses standards de qualité.
					</p>
				</div>
			</div>

			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 13 - Obligations de l'acheteur</h1>{' '}
				<div>
					<p className=''>
						Afin de faciliter la bonne exécution des prestations, l'acheteur
						s'engage :
					</p>

					<ul className='pl-4'>
						<li>
							- à fournir au prestataire des informations et documents complets,
							exacts et dans les délais nécessaires sans qu'il soit tenu d'en
							vérifier le caractère complet ou l'exactitude ;
						</li>
						<li>
							- à prendre les décisions dans les délais et d'obtenir les
							approbations hiérarchiques nécessaires ;
						</li>
						<li>
							- à désigner un correspondant investi d'un pouvoir de décision ;
						</li>
						<li>
							- à faire en sorte que les interlocuteurs clés et le correspondant
							soient disponibles tout au long de l'exécution des prestations ;
						</li>
						<li>
							- à avertir directement le prestataire de toute difficulté
							éventuelle relative à l'exécution des prestations.
						</li>
					</ul>
				</div>
			</div>

			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 14 - Informations et publicité</h1>{' '}
				<div>
					<p className=''>L'acheteur reconnaît et accepte :</p>

					<ul className='pl-4'>
						<li>
							- que les parties pourront sauf demande expresse contraire de
							l'autre partie, correspondre ou transférer des documents par
							courrier électronique circulant sur le réseau internet ;
						</li>
						<li>
							- qu'aucune des parties n'exerce de maîtrise sur la capacité, la
							fiabilité, l'accès ou la sécurité de ces courriers électroniques ;
						</li>
						<li>
							- que le prestataire ne saura être tenu pour responsables de toute
							perte, dommage, frais ou préjudice occasionnés par la perte, le
							retard, l'interception, le détournement ou l'altération de tout
							courrier électronique causés par un fait quelconque. De façon
							générale, les parties s'engagent à respecter la réglementation
							applicable à la protection des données personnelles et notamment
							les dispositions de la loi 78-17 du 6 janvier 1978 relative à
							l'informatique, aux fichiers et aux libertés.
						</li>
					</ul>
					<p>
						<b> Informations confidentielles</b>
						<br />
						Chacune des parties s'engage à ne pas divulguer les informations
						confidentielles reçues de l'autre partie. Les informations
						confidentielles s'entendent des informations de toute nature,
						visuelles ou orales, sur quelque support que ce soit, relatives à la
						structure, l'organisation, les affaires, les politiques internes
						diverses, les projets et le personnel de chacune des parties. Sous
						réserve des exceptions visées ci-après, la présente obligation de
						confidentialité produira ses effets pendant la durée suivante : 10
						ans suivant le terme des prestations. Ont également un caractère
						confidentiel, le contenu des prestations ainsi que les rapports,
						courriers, informations, notes, devis, fournis par le prestataire au
						cours de l'exécution des prestations. Ces documents sont communiqués
						à l'acheteur pour un usage strictement interne et à la condition de
						ne pas les divulguer à des tiers ni de les annexer à un document
						qu'il serait amené à produire. Si l'acheteur souhaite que tout ou
						partie de ces documents soient divulgués à/ou utilisés par un tiers,
						il doit en demander l'autorisation préalable par écrit au
						prestataire. Des modalités applicables à cette divulgation seront
						alors fixées.
					</p>
				</div>
				<div>
					<b>Informations exclues</b>
					<br />
					Les obligations et restrictions énoncées ci-dessus ne s'appliquent pas
					:{' '}
					<ul className='pl-4'>
						<li>
							aux informations confidentielles qui appartiennent au domaine
							public, ou ont été acquises librement avant le début de la
							prestation ;
						</li>
						<li>
							sont ou deviennent connues autrement qu'à la suite d'une violation
							du présent article ;
						</li>
						<li>
							sont ou deviennent connues grâce à d'autres sources non tenues à
							une restriction de divulgation ;
						</li>
						<li>
							ou doivent être communiquées en vertu d'une obligation légale ou
							professionnelle ou à la demande de toute autorité judiciaire ou
							réglementaire habilitée à exiger la divulgation des informations
							confidentielles.
						</li>
					</ul>
					<p>
						Sous réserve de ses obligations en matière de confidentialité, le
						prestataire se réserve le droit d'exécuter des prestations pour des
						entreprises concurrentes de celle de l'acheteur.
					</p>
				</div>
			</div>

			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 15 - Propriété intellectuelle</h1>{' '}
				<div>
					<p className=''>
						Au cas où l'une des recommandations du prestataire ou l'utilisation
						d'éléments livrés à la suite de l'une de ses préconisations
						impliquerait l'utilisation de biens, modèles, dessins,
						photographies, etc. faisant l'objet de droits de propriété
						intellectuelle appartenant à des tiers, le prestataire informera
						l'acheteur de l'existence de ces droits et des conséquences de leur
						utilisation. Il appartiendra alors à l'acheteur et sous sa seule
						responsabilité de prendre toute mesure permettant l'utilisation de
						tels droits, notamment en négociant pour son propre compte les
						droits d'utilisation dans des conditions telles que le prestataire
						soit en mesure de s'en prévaloir pour les besoins des prestations.
						<br />
						<br />
						Pour les besoins propres des prestations, le prestataire pourra
						utiliser ou développer des logiciels, y compris des feuilles de
						calculs, des documents, des bases de données et d'autres outils
						informatiques. Dans certains cas, ces aides peuvent être mises à la
						disposition de l'acheteur et sur sa demande. Dans la mesure où ces
						outils ont été développés spécifiquement pour les besoins du
						prestataire et sans considération des besoins propres de l'acheteur,
						ceux-ci sont mis à disposition de l'acheteur pendant la durée du
						contrat en l'état et sans aucune garantie attachée, à simple
						destination d'usage ; ils ne devront être distribués, partagés ou
						communiqués à des tiers que ce soit en tout ou partie. Cette mise à
						disposition temporaire n'emportera aucune cession de droits ni
						garantie, quel qu'en soit le titre, au bénéfice de l'acheteur ou
						celui du tiers. Le prestataire se réserve tout droit, titre et
						intérêt sur :
					</p>
					<ul className='pl-4'>
						<li>
							les éléments originaux figurant dans les travaux, documents,
							mémos, consultations, avis, conclusions ou autres actes de
							procédure, etc. réalisés dans le cadre des prestations, y compris
							de façon non limitative, tout droit d'auteur, marque déposée et
							tout autre droit de propriété intellectuelle s'y rapportant ;
						</li>
						<li>
							toutes les méthodes, processus, techniques, développements, et
							savoir-faire incorporés ou non des prestations ou que le
							prestataire serait amené à développer ou à fournir dans le cadre
							des prestations.
						</li>
					</ul>

					<p>
						L'acheteur pourra, sans limitation géographique, à titre gratuit et
						irrévocable, utiliser de manière interne et pour la durée de
						protection par le droit d'auteur, les éléments conçus par le
						prestataire et intégrés dans ses travaux. L'acheteur s'interdit de
						distribuer, commercialiser, et plus généralement de mettre à
						disposition ou de concéder l'utilisation de ces mêmes réalisations
						et plus généralement de concéder l'utilisation de ces mêmes éléments
						à des tiers sans l'accord du prestataire. Aucune partie ne pourra
						faire mention ou usage du nom, de la dénomination, des marques et
						logos ou autres appellations, commerciales ou non, de l'autre partie
						sans accord préalable et écrit de cette dernière. Par dérogation à
						ce qui précède, le prestataire pourra faire usage du nom, de la
						dénomination, des marques et logos de l'acheteur en cours de contrat
						dans la mesure de ce qui est strictement nécessaire à l'exécution
						des prestations, y compris dans des propositions de prestations
						ultérieures. Par ailleurs, l'acheteur autorise le prestataire, à
						l'issue de la réalisation des prestations, à citer son
						nom/dénomination à titre de référence et accompagner cette citation,
						le cas échéant, d'une description générique des prestations
						effectuées.
					</p>
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 16 - Documents</h1>{' '}
				<div>
					<p className=''>
						Le prestataire conservera les documents originaux qui lui auront été
						remis, et les restituera à l'acheteur, sur sa demande. Tous les
						documents, données ou informations, que l'acheteur aura fournies,
						resteront sa propriété. Le prestataire conservera une copie des
						seuls documents nécessaires à la constitution de ses dossiers de
						travail. Les documents de travail préparés dans le cadre des
						prestations sont notre propriété et sont couverts par le secret
						professionnel.
					</p>
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 17 - Indépendance</h1>{' '}
				<div>
					<p className=''>
						Dans l'hypothèse où un conflit d'intérêt ou une problématique
						d'indépendance surviendrait au cours de l'exécution des prestations,
						le prestataire en fera part immédiatement à l'acheteur et
						recherchera avec lui la solution la plus adaptée à la situation dans
						le respect des règles applicables. Plus particulièrement, si une
						modification de la réglementation ou des normes professionnelles
						interdisait au prestataire de poursuivre ses prestations, il mettra
						à la disposition de l'acheteur le résultat des prestations ainsi que
						tous documents nécessaires à leur finalisation, y compris ses
						Documents en l'état, et ce, afin d'en faciliter la poursuite par un
						tiers.
					</p>
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>
					Article 18 - Responsabilité du prestataire
				</h1>{' '}
				<div>
					<p className=''>
						L'entière responsabilité du prestataire et celle de ses
						collaborateurs relative à tout manquement, négligence ou faute,
						relevé à l'occasion de l'exécution des prestations, sera plafonnée
						au montant des honoraires versés au titre des prestations mises en
						cause, afin de couvrir les réclamations de toute nature (intérêts et
						frais inclus), et ce, quel que soit le nombre d'actions, de
						fondements invoqués, ou de parties aux litiges. Cette stipulation ne
						s'appliquera pas à une responsabilité pour décès ou blessure
						corporelle, ni à toute autre responsabilité que la loi interdit
						d'exclure ou de limiter. La responsabilité du prestataire ne peut
						être engagée qu'en cas de faute ou de négligence prouvée et est
						limitée aux préjudices directs à l'exclusion de tout préjudice
						indirect, de quelque nature que ce soit. Par ailleurs, la
						responsabilité du prestataire ne pourra être engagée dans les cas
						suivants :
					</p>
					<ul className='pl-4'>
						<li>
							suite à un manquement ou à une carence d'un produit ou d'un
							service dont la fourniture ou la livraison ne lui incombe pas ni à
							ses sous-traitants éventuels ;
						</li>
						<li>
							pour les faits et/ou données qui n'entrent pas dans le périmètre
							des prestations, et/ou qui n'en sont pas le prolongement ;
						</li>
						<li>
							en cas d'utilisation des résultats des prestations, pour un objet
							ou dans un contexte différent de celui dans lequel il est
							intervenu, de mise en oeuvre erronée des recommandations ou
							d'absence de prise en compte des réserves du prestataire.
						</li>
					</ul>
					<p>
						Le prestataire ne répond ni ses assureurs ni des dommages indirects,
						ni du manque à gagner ou de la perte de chance ou de bénéfices
						escomptés, ni des conséquences financières des actions
						éventuellement intentées par des tiers à l'encontre de l'acheteur.
					</p>
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 19 - Garantie</h1>{' '}
				<div>
					<p className=''>
						Le prestataire garantit l'acheteur contre tout défaut de conformité
						des services et tout vice caché provenant d'un défaut de conception
						ou de fourniture desdits services à l'exclusion de toute négligence
						ou faute de l'acheteur. En tout état de cause, au cas où la
						responsabilité du prestataire serait retenue, la garantie du
						prestataire serait limitée au montant HT payé par l'acheteur pour la
						fourniture des services.
					</p>
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>
					Article 20 - Cessibilité et sous-traitance
				</h1>{' '}
				<div>
					<p className=''>
						Le prestataire se réserve le droit de céder tout ou partie de
						l'exécution des prestations à des prestataires répondant aux mêmes
						exigences de qualification. Si la prestation requiert des
						compétences techniques particulières, le prestataire informera
						l'acheteur sur la possibilité d'en sous-traiter une partie. Le
						sous-traitant interviendra alors sous la seule responsabilité du
						prestataire et s'engagera à conserver confidentielles toutes les
						informations dont il aura connaissance à l'occasion des prestations.
					</p>
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 21 - Réclamations</h1>{' '}
				<div>
					<p className=''>
						Toutes les réclamations, qu'elles soient amiables ou judiciaires,
						relatives à l'exécution des prestations devront être formulées dans
						un délai d'une année à compter de la fin de la réalisation de la
						prestation.
					</p>
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 22 - Droit de rétractation</h1>{' '}
				<div>
					<p className=''>
						L'acheteur étant un professionnel achetant dans le cadre et pour les
						besoins de sa profession, il n'y a pas lieu d'appliquer le droit de
						rétractation prévu par le code de la consommation.
					</p>
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 23 - Force majeure</h1>{' '}
				<div>
					<p className=''>
						Toutes circonstances indépendantes de la volonté des parties,
						empêchant l'exécution dans des conditions normales de leurs
						obligations, sont considérées comme des causes d'exonération des
						obligations des parties et entraînent leur suspension. La partie qui
						invoque les circonstances visées ci-dessus doit avertir
						immédiatement l'autre partie de leur survenance, ainsi que de leur
						disparition. Seront considérés comme cas de force majeure tous faits
						ou circonstances irrésistibles, extérieurs aux parties,
						imprévisibles, inévitables, indépendants de la volonté des parties
						et qui ne pourront être empêchés par ces dernières, malgré tous les
						efforts raisonnablement possibles. De façon expresse, sont
						considérés comme cas de force majeure ou cas fortuits, outre ceux
						habituellement retenus par la jurisprudence des cours et des
						tribunaux français : le blocage des moyens de transports ou
						d'approvisionnements, tremblements de terre, incendies, tempêtes,
						inondations, foudre, l'arrêt des réseaux de télécommunication ou
						difficultés propres aux réseaux de télécommunication externes aux
						clients. Les parties se rapprocheront pour examiner l'incidence de
						l'événement et convenir des conditions dans lesquelles l'exécution
						du contrat sera poursuivie. Si le cas de force majeure a une durée
						supérieure à trois mois, les présentes conditions générales pourront
						être résiliées par la partie lésée.
					</p>
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 24 - Non-validation partielle</h1>{' '}
				<div>
					<p className=''>
						Si une ou plusieurs stipulations des présentes conditions générales
						sont tenues pour non valides ou déclarées telles en application
						d'une loi, d'un règlement ou à la suite d'une décision définitive
						d'une juridiction compétente, les autres stipulations garderont
						toute leur force et leur portée.
					</p>
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 25 - Non-renonciation</h1>{' '}
				<div>
					<p className=''>
						Le fait pour l'une des parties de ne pas se prévaloir d'un
						manquement par l'autre partie à l'une quelconque des obligations
						visées dans les présentes conditions générales ne saurait être
						interprété pour l'avenir comme une renonciation à l'obligation en
						cause.
					</p>
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 26 - Titre</h1>{' '}
				<div>
					<p className=''>
						En cas de difficulté d'interprétation entre l'un quelconque des
						titres figurant en tête des clauses, et l'une quelconque des
						clauses, les titres seront déclarés inexistants.
					</p>
				</div>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>
					Article 27 - Protection des données personnelles
				</h1>{' '}
				<div>
					<p className=''>
						<b>
							<u>Données collectées</u>
						</b>
						<br />
						Les données à caractère personnel qui sont collectées sur ce site
						sont les suivantes :
					</p>
					<ul>
						<li>
							<b>ouverture de compte:</b> lors de la création du compte de
							l'utilisateur, ses nom ; prénom ; adresse électronique ; n° de
							téléphone ; adresse postale ; Bancaires, Base de données clients
						</li>
						<li>
							<b>connexion :</b> lors de la connexion de l'utilisateur au site
							web, celui-ci enregistre, notamment, ses nom, prénom, données de
							connexion, d'utilisation, de localisation et ses données relatives
							au paiement ;
						</li>
						<li>
							<b>profil :</b> l'utilisation des prestations prévues sur le site
							web permet de renseigner un profil, pouvant comprendre une adresse
							et un numéro de téléphone ;
						</li>
						<li>
							<b>paiement :</b> dans le cadre du paiement des produits et
							prestations proposés sur le site web, celui-ci enregistre des
							données financières relatives au compte bancaire ou à la carte de
							crédit de l'utilisateur ;
						</li>
						<li>
							<b>communication :</b> lorsque le site web est utilisé pour
							communiquer avec d'autres membres, les données concernant les
							communications de l'utilisateur font l'objet d'une conservation
							temporaire ;
						</li>
						<li>
							<b>cookies :</b> les cookies sont utilisés, dans le cadre de
							l'utilisation du site. L'utilisateur a la possibilité de
							désactiver les cookies à partir des paramètres de son navigateur.
						</li>
					</ul>
					<p>
						<b>
							<u>Utilisation des données personnelles</u>
						</b>
						<br />
					</p>
					<ul>
						- gestion des éventuels litiges avec les utilisateurs ;
						<li>- accès et utilisation du site web par l'utilisateur ;</li>
						<li>- gestion du fonctionnement et optimisation du site web ;</li>
						<li>
							- organisation des conditions d'utilisation des Services de
							paiement ;
						</li>
						<li>
							- vérification, identification et authentification des données
							transmises par l'utilisateur ;
						</li>
						<li>
							- proposition à l'utilisateur de la possibilité de communiquer
							avec d'autres utilisateurs du site web ;
						</li>
						<li>- mise en oeuvre d'une assistance utilisateurs ;</li>
						<li>
							- personnalisation des services en affichant des publicités en
							fonction de l'historique de navigation de l'utilisateur, selon ses
							préférences ;
						</li>
						<li>
							- prévention et détection des fraudes, malwares (malicious
							softwares ou logiciels malveillants) et gestion des incidents de
							sécurité ;
						</li>
						<li>
							Les données personnelles collectées auprès des utilisateurs ont
							pour objectif la mise à disposition des services du site web, leur
							amélioration et le maintien d'un environnement sécurisé. Plus
							précisément, les utilisations sont les suivantes :
						</li>
						<li>
							- envoi d'informations commerciales et publicitaires, en fonction
							des préférences de l'utilisateur.
						</li>
					</ul>
				</div>
				<p>
					<b>
						<u>Partage des données personnelles avec des tiers</u>
					</b>
					<br />
					Les données personnelles peuvent être partagées avec des sociétés
					tierces, dans les cas suivants :
				</p>
				<ul>
					<li>
						- quand l'utilisateur utilise les services de paiement, pour la mise
						en oeuvre de ces services, le site web est en relation avec des
						sociétés bancaires et financières tierces avec lesquelles elle a
						passé des contrats ;
					</li>
					<li>
						- lorsque l'utilisateur publie, dans les zones de commentaires
						libres du site web, des informations accessibles au public ;
					</li>
					<li>
						- quand l'utilisateur autorise le site web d'un tiers à accéder à
						ses données ;
					</li>
					<li>
						- quand le site web recourt aux services de prestataires pour
						fournir l'assistance utilisateurs, la publicité et les services de
						paiement. Ces prestataires disposent d'un accès limité aux données
						de l'utilisateur, dans le cadre de l'exécution de ces prestations,
						et ont une obligation contractuelle de les utiliser en conformité
						avec les dispositions de la réglementation applicable en matière
						protection des données à caractère personnel ;
					</li>
					<li>
						- si la loi l'exige, le site web peut effectuer la transmission de
						données pour donner suite aux réclamations présentées contre le site
						web et se conformer aux procédures administratives et judiciaires ;
					</li>
					<li>
						- si le site web est impliquée dans une opération de fusion,
						acquisition, cession d'actifs ou procédure de redressement
						judiciaire, elle pourra être amenée à céder ou partager tout ou
						partie de ses actifs, y compris les données à caractère personnel.
						Dans ce cas, les utilisateurs seraient informés, avant que les
						données à caractère personnel ne soient transférées à une tierce
						partie.
					</li>
				</ul>
				<p>
					<b>
						<u>Sécurité et confidentialité</u>
					</b>
					<br />
					Le site web met en oeuvre des mesures organisationnelles, techniques,
					logicielles et physiques en matière de sécurité du numérique pour
					protéger les données personnelles contre les altérations, destructions
					et accès non autorisés. Toutefois, il est à signaler qu'internet n'est
					pas un environnement complètement sécurisé et le site web ne peut pas
					garantir la sécurité de la transmission ou du stockage des
					informations sur internet.
				</p>
				<p>
					<b>
						<u> Evolution de la présente clause</u>
					</b>
					<br />
					Le site web se réserve le droit d'apporter toute modification à la
					présente clause relative à la protection des données à caractère
					personnel à tout moment. Si une modification est apportée à la
					présente clause de protection des données à caractère personnel, le
					site web s'engage à publier la nouvelle version sur son site. Le site
					web informera également les utilisateurs de la modification par
					messagerie électronique, dans un délai minimum de 15 jours avant la
					date d'effet. Si l'utilisateur n'est pas d'accord avec les termes de
					la nouvelle rédaction de la clause de protection des données à
					caractère personnel, il a la possibilité de supprimer son compte.
				</p>
				<p>
					<b>
						<u>Mise en oeuvre des droits des utilisateurs</u>
					</b>
					<br />
					En application de la réglementation applicable aux données à caractère
					personnel, les utilisateurs disposent des droits ci-dessous
					mentionnés, qu'ils peuvent exercer en faisant leur demande à l'adresse
					suivante : contact@mapimimmo.fr
				</p>
				<ul>
					<li>
						Le droit d’accès : ils peuvent exercer leur droit d'accès, pour
						connaître les données personnelles les concernant. Dans ce cas,
						avant la mise en œuvre de ce droit, le site web peut demander une
						preuve de l'identité de l'utilisateur afin d'en vérifier
						l'exactitude.
					</li>
					<li>
						Le droit de rectification : si les données à caractère personnel
						détenues par le site web sont inexactes, ils peuvent demander la
						mise à jour des informations.
					</li>
					<li>
						Le droit de suppression des données : les utilisateurs peuvent
						demander la suppression de leurs données à caractère personnel,
						conformément aux lois applicables en matière de protection des
						données.
					</li>
					<li>
						Le droit à la limitation du traitement : les utilisateurs peuvent de
						demander au site web de limiter le traitement des données
						personnelles conformément aux hypothèses prévues par le RGPD.
					</li>
					<li>
						Le droit de s’opposer au traitement des données : les utilisateurs
						peuvent s’opposer à ce que ses données soient traitées conformément
						aux hypothèses prévues par le RGPD.
					</li>
					<li>
						Le droit à la portabilité : ils peuvent réclamer que le site web
						leur remette les données personnelles qui lui sont fournies pour les
						transmettre à un nouveau site web.
					</li>
				</ul>
			</div>
			<div className='text-xl text-black/90 mt-8'>
				<h1 className='font-bold'>Article 28 - Loi applicable</h1>{' '}
				<div>
					<p className=''>
						Les présentes conditions générales sont soumises à l'application du
						droit français. Elles sont rédigées en langue française. Dans le cas
						où elles seraient traduites en une ou plusieurs langues, seul le
						texte français ferait foi en cas de litige. Les parties s'engagent à
						rechercher une solution amiable à tout différend qui pourrait naître
						de la réalisation des prestations. Si elles n'y parviennent pas, les
						parties soumettront le litige au tribunal de commerce compétent.
					</p>
				</div>
			</div>
		</div>
	);
};
