import { useNavigate } from 'react-router-dom';
import ImagesSection from '../../../components/generals/contact/section/images.section';
import { NewsCard } from '../../../components/generals/news/NewsCard';
import { Button } from '../../../components/commons/button';

function ContactSection({ contact }) {
	const navigate = useNavigate();
	return (
		<>
			<div className='px-5 py-2 my-3 rounded blue-color items-center flex justify-between'>
				<p className='text-white uppercase block'> - INFORMATIONS GENERALES </p>
			</div>
			<div className='flex justify-between flex-wrap p-4 items-start'>
				<div className='grid grid-cols-1 lg:grid-rows-1 gap-5  w-full sm:w-1/3'>
					<div className='flex items-center justify-between'>
						<p className='text-[#2d5c6a] w-1/4'>Addresse :</p>
						<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-auto'>
							{contact?.adress_contact}
						</div>
					</div>
					<div className='flex items-start justify-between'>
						<p className='text-[#2d5c6a] w-1/4'>Ville :</p>
						<div className='flex flex-col gap-y-2 justify-center w-[70%] mx-auto'>
							<div className='w-full mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								{contact?.city_contact}
							</div>
							<div className='w-full mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								{contact?.zip_contact}
							</div>
						</div>
					</div>
					<div className='flex items-center justify-between'>
						<p className='text-[#2d5c6a] w-1/4'>Téléphone :</p>
						<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
							{contact?.phone}
						</div>
					</div>
					<div className='flex items-center justify-between'>
						<p className='text-[#2d5c6a] w-1/4'>Email :</p>
						<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
							{contact?.mail}
						</div>
					</div>
					<div className='flex items-center justify-between'>
						<p className='text-[#2d5c6a] w-1/4'>Agence :</p>
						<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
							{contact?.agency?.nameAgency}
						</div>
					</div>
					<div className='flex items-center justify-between'>
						<p className='text-[#2d5c6a] w-1/4'>Négociateur :</p>
						<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
							{contact?.negociator?.name}
						</div>
					</div>
					<div className='flex items-center justify-between'>
						<p className='text-[#2d5c6a] w-1/4'>Mandat(s) associé(s) :</p>
						<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-auto'>
							{contact.mandate.map((item, index) => (
								<Button
									key={index}
									type={'button'}
									onClick={() => {
										if (item.bien) {
											navigate(`/mes-biens/details-bien/${item.bien.uuid}`);
										}
									}}
									className={`blue-color-i rounded-xl`}>
									{item.num_mandat}
								</Button>
							))}
						</div>
					</div>
				</div>
				<div className='lg:flex  lg:flex-col grid grid-cols-1 w-full sm:w-2/3'>
					<div className='border border-[#2d5c6a] h-full'>
						<p className='text-[#2d5c6a] text-center'>
							Plus d'infos d'actualités :
						</p>
						<div className='mx-2'>
							{contact.historiques &&
								contact.historiques
									.reverse()
									.map((item, key) => <NewsCard news={item} key={key} />)}
						</div>
					</div>
				</div>
			</div>

			<div className='px-5 py-2 mt-5 rounded blue-color items-center flex justify-between'>
				<p className='text-white uppercase block'> - RAPPROCHEMENTS </p>
			</div>
			<ImagesSection contact={contact} />
		</>
	);
}
export default ContactSection;
