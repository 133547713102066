import { useEffect, useState } from 'react';
import { Breadcumbs } from '../../components/generals/headers/breadcumbs';
import { NewsLoading } from './NewsLoading';
import style from './news.module.css';
import { NewsCard } from '../../components/generals/news/NewsCard';
import { useNewsService } from '../../service/news/useNewsService';
import { GlobalNews } from '../../components/generals/news/GlobalNews';
import { Pagination } from '../../components/commons/pagination';

export const News = () => {
	const [news, setNews] = useState();
	const [loading, setLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [pagination, setPagination] = useState();

	useEffect(() => {
		fetchNews();
		setLoading(false);
	}, [currentPage]);

	const fetchNews = () => {
		useNewsService.fetchAllNews(currentPage).then((response) => {
			setNews(response.data.data);

			setPagination({
				links: response.data.links,
				per_page: response.data.per_page,
				total: response.data.total
			});
			setLoading(false);
		});
	};

	return (
		news && (
			<>
				<Breadcumbs title={'Actualités'} />
				<div className={style.news__container}>
					{loading && [1, 2, 3].map((item, key) => <NewsLoading key={key} />)}
					{news &&
						news.map((item, key) => (
							<div
								key={key}
								className='w-full p-4 m-0 bg-gray-500 rounded cursor-pointer text-white'>
								<GlobalNews news={item} key={key} />
							</div>
						))}
				</div>
				{pagination.links && pagination.links.length !== 0 && (
					<div className='h-12 mt-10 flex flex-wrap w-full gap-1 justify-center items-center'>
						{pagination.links
							.slice(1, pagination.links.length)
							.slice(0, -1)
							.map((link, index) => {
								return (
									<Pagination
										changePage={(page) => setCurrentPage(page)}
										key={index}
										link={link}></Pagination>
								);
							})}
					</div>
				)}
			</>
		)
	);
};
