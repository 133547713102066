import { useNavigate } from 'react-router-dom';
import { ColorText } from './BienText';
import { BackgroundColor } from '../../../../helpers/backgroundcolor';

export const FolderText = ({ actus }) => {
	const navigate = useNavigate();
	return (
		<div
			className='flex items-stretch justify-between py-2 mx-2'
			onClick={() =>
				navigate('/mes-biens/details-bien/' + JSON.parse(actus.content).uuid)
			}>
			<div className='text-center'>
				Création du suivi de dossier pour le bien N°{' '}
				{JSON.parse(actus?.content)?.num_folder}
			</div>
			<ColorText color={BackgroundColor('green')} />
		</div>
	);
};
