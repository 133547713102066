import { EnvoisMail } from './Json/Contact/EnvoisMail';
import { Information } from './Json/Contact/Information';
import { InfosPrincipale } from './Json/Contact/InfosPrincipale';
import { PlusCriteriaBien } from './Json/Contact/PlusCriteria';
import { PlusCriteriaTerrain } from './Json/Contact/PlusCriteriaTerrain';
import { InfosComplementaire } from './Json/Contact/infosComplementaire';
import { Mandat } from './Json/Mandat';

export const contactForm = {
	completeText: 'Ajouter un contact',
	title: 'Ajouter un contact',
	steps: [
		{
			group: 'info_personnelle',
			title: 'Information principales',
			//Info complémentaires
			substeps: [
				{
					group: 'info_complementaire',
					title: 'Informations Complémentaires',
					elements: InfosComplementaire
				},
				// Dernier étape de saisie
				{
					group: 'info_complementaire',
					title: 'Envois mail',
					elements: EnvoisMail
				}
			],
			elements: InfosPrincipale
		},
		{
			group: 'info_personnelle',
			title: 'Information de biens',
			//Info complémentaires
			elements: Information,
			substeps: [
				{
					title: 'Plus de critère du bien',
					group: 'info_complementaire',
					elements: PlusCriteriaBien
				},
				{
					group: 'info_complementaire',
					title: 'Plus de critère de terrain',
					elements: PlusCriteriaTerrain
				},
				{
					group: 'mandat',
					elements: Mandat,
					title: 'Mandat'
				}
			]
		}
	]
};
