import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EstimationService } from '../../service/estimation';
import { GeneralSpinner } from '../../components/commons/general-spinner';
import { Breadcumbs } from '../../components/generals/headers/breadcumbs';
import { dateFormater, formatter } from '../../helpers/formatter';
import { successToast } from '../../service/toast/showToast';

export const EstimationDetail = () => {
	const { estimationUuid } = useParams();
	const PERCENT = 5;

	const calculateVariation = (value) => {
		const fivePercent = Math.ceil((value * PERCENT) / 100);
		return {
			min: Math.ceil((value - fivePercent) / 100) * 100,
			max: Math.ceil((value + fivePercent) / 100) * 100
		};
	};

	const sendEstimationToMail = () => {
		successToast("Envoie de mail de l'estimation initié");
		EstimationService.sendEstimationToMail(estimationUuid).then(({ data }) =>
			successToast("L'estimation est en cours de traitement")
		);
	};

	const [estimation, setEstimation] = useState();
	useEffect(() => {
		EstimationService.getEstimation(estimationUuid).then((response) => {
			const data = response.data;
			data.estimation_value = calculateVariation(data.estimation_value);
			data.details_bien = JSON.parse(data.details_bien);
			data.dispositions.map((disposition) => {
				disposition.parcelles = disposition.parcelles.map((parcelle) => {
					parcelle.locaux = parcelle.locaux.filter(
						(local) =>
							local['majic_nb_pieces_principales'] ==
								data.details_bien.nb_pieces &&
							local['majic_surface_reelle_bati'] >=
								data.details_bien.surface_habitable - 10 &&
							local['majic_surface_reelle_bati'] <=
								data.details_bien.surface_habitable + 10 &&
							local['l_majic_code_type_local'] == data.bien
					);

					return parcelle;
				});
			});

			setEstimation(data);
		});
	}, []);
	return (
		(estimation && (
			<>
				<Breadcumbs
					title={`Estimation - ${estimation.bien} du ${dateFormater(
						estimation.created_at
					)}`}
				/>
				<div className='border-[1px] border-solid border-black/30 '>
					<div className='flex p-2 gap-2'>
						<div className='lg:w-2/5 w-full border-[4px] border-solid border-red-700/50 p-4'>
							<h2 className='text-lg uppercase font-bold w-full blue-color text-white px-auto py-2 text-center'>
								Information sur le / la démandeur (euse)
							</h2>
							<div>
								<ul>
									<li>Prénoms : {estimation.demandeur_firstname}</li>
									<li>Nom : {estimation.demandeur_lastname}</li>
									<li>Email : {estimation.demandeur_email}</li>
								</ul>
							</div>
							<button
								onClick={sendEstimationToMail}
								className='uppercase font-bold default-color text-white p-2 text-center rounded mt-6'>
								Envoyer le resultat par mail
							</button>
						</div>
						<div className='lg:w-3/5 w-full border-[4px] border-solid border-red-700/50 p-4'>
							<h2 className='text-lg uppercase font-bold w-full blue-color text-white px-auto py-2 text-center'>
								Information sur l'estimation
							</h2>
							<div>
								<ul>
									<li>Recherche : {estimation.bien}</li>
									<li>
										Addresse : {estimation.address_bien} -{' '}
										{estimation.ville_bien}
									</li>
									<li>
										Surface : {estimation.details_bien.surface_habitable} m
										<sup>2</sup>
									</li>
									<li>
										Nombre de pièces : {estimation.details_bien.nb_pieces}
									</li>
									<li>
										Nombre de chambres : {estimation.details_bien.nb_chambre}
									</li>
									<li>
										Nombre de salle d'eau :{' '}
										{estimation.details_bien.nb_salle_eau}
									</li>
									<li>
										Nombre de salle de bain :{' '}
										{estimation.details_bien.nb_salle_bain}
									</li>

									{(estimation.estimation_value.min > 0 && (
										<li>
											Valeur estimée de la propriété :{' '}
											<span className='bg-green-600/70 p-1 rounded-lg text-white/90'>
												{formatter.format(estimation.estimation_value.min)}
											</span>{' '}
											-{' '}
											<span className='bg-green-600/70 p-1 rounded-lg text-white/90'>
												{formatter.format(estimation.estimation_value.max)}
											</span>
											<p>
												(En raison des variations possibles, nous vous proposons
												un prix avec marge de {PERCENT}%)
											</p>
										</li>
									)) || (
										<p className='bg-red-200 p-1'>
											L'estimation n'a pas pu se faire parce qu'aucune donnée
											n'a été trouvé correspondant à vos descriptions
										</p>
									)}
								</ul>
							</div>
						</div>
					</div>
					<div className='w-full p-2'>
						<h2 className='text-lg uppercase font-bold w-full blue-color text-white px-auto py-2 text-center'>
							Information sur les dispositions correspondantes
						</h2>
						<div className='w-full p-4'>
							{estimation.dispositions.map((disposition, index) => {
								const filteredParcelle = disposition.parcelles.filter(
									(parcelle) =>
										parcelle.locaux.filter(
											(local) =>
												local['majic_nb_pieces_principales'] ==
													estimation.details_bien.nb_pieces &&
												local['majic_surface_reelle_bati'] >=
													estimation.details_bien.surface_habitable - 10 &&
												local['majic_surface_reelle_bati'] <=
													estimation.details_bien.surface_habitable + 10 &&
												local['l_majic_code_type_local'] == estimation.bien
										).length > 0
								);

								if (filteredParcelle.length > 0)
									return (
										<div
											key={index}
											className='border-[1px] border-solid border-black/50 my-2 flex justify-between items-stretch min-h-[100px] gap-3'>
											<div className='w-1/12 flex items-center justify-center blue-color text-white font-bold text-2xl'>
												{index + 1}
											</div>
											<div className='w-11/12 flex items-center py-4'>
												<div>
													<ul className='w-full'>
														<li className='my-1'>
															Adresse du bien :{' '}
															<span className='bg-blue-600/70 p-1 rounded-lg text-white/90'>
																{
																	disposition.parcelles.filter(
																		(parcelle) => parcelle.locaux.length != 0
																	)[0].majic_adresse
																}
															</span>
														</li>
														<li className='my-1'>
															Surface habitable :{' '}
															<span className='bg-blue-600/70 p-1 rounded-lg text-white/90'>
																{
																	disposition.parcelles.filter(
																		(parcelle) => parcelle.locaux.length != 0
																	)[0].locaux[0]?.majic_surface_reelle_bati
																}
																m<sup>2</sup>
															</span>
														</li>
														<li className='my-1'>
															Nombre de pièces :{' '}
															<span className='bg-blue-600/70 p-1 rounded-lg text-white/90'>
																{
																	disposition.parcelles.filter(
																		(parcelle) => parcelle.locaux.length != 0
																	)[0].locaux[0]?.majic_nb_pieces_principales
																}
															</span>
														</li>
														<li className='my-1'>
															Valeur foncière :{' '}
															<span className='bg-blue-600/70 p-1 rounded-lg text-white/90'>
																{formatter.format(
																	disposition.fidji_valeur_fonciere
																)}{' '}
															</span>
														</li>
													</ul>
												</div>
											</div>
										</div>
									);
							})}
						</div>
					</div>
				</div>
			</>
		)) || <GeneralSpinner />
	);
};
