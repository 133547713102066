import { VisiteBloc } from '../bloc/visite';

export const VisiteSection = ({ visites }) => {
	return (
		<>
			<div className='px-5 py-1 mt-5 blue-color text-center font-bold text-white text-2xl'>
				<h1>COMPTE RENDU DE VISITE</h1>
			</div>

			{(visites.length == 0 && (
				<div className='text-center text-2xl p-4'>
					Il n'y a pas de compte rendu de visite pour ce bien
				</div>
			)) || (
				<div className='p-2'>
					{visites.map((visite, index) => (
						<VisiteBloc visite={visite} key={index} />
					))}
				</div>
			)}
		</>
	);
};
