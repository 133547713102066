import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { LocalisationService } from '../../../../../service/models/localisation';
import { ButtonSpinner } from '../../../buttonSpinner';

export const LocalityCityUpdate = ({
	style,
	element,
	errors,
	register,
	unregister,
	dataUpdate,
}) => {
	const { setValue, getValues } = useFormContext();
	const [currentAdress, setCurrentAdress] = useState(new Set());
	const [showLoading, setShowLoading] = useState(false);
	const [showChoices, setShowChoices] = useState(false);
	const [searchResults, setSearchResults] = useState([]);

	const setAddress = (value) => {
		setShowChoices(false);
		setCurrentAdress((prev) => new Set([...prev, value]));
	};

	const removeItem = (value) => {
		setCurrentAdress(
			(prev) => new Set([...prev].filter((item) => item !== value))
		);
	};

	const getAddress = async () => {
		const value = getValues(element.name + '4');
		const showChoices = value.length > 3 ? true : false;

		if (showChoices) {
			setShowLoading(true);
			await LocalisationService.searchCity(value)
				.then(({ data }) => {
					setShowChoices(true);
					setSearchResults(data);
				})
				.catch(() => {})
				.finally(() => {});
		} else {
		}
		setShowLoading(false);
	};
	useEffect(() => {
		if (dataUpdate[element.name]) {
			setValue(element.name + '4', dataUpdate[element.name]);
			setCurrentAdress(dataUpdate[element.name].split(','));
		}
	}, []);

	useEffect(() => {
		unregister(element.name);
	}, [currentAdress]);

	return (
		<>
			<small className='w-full'>
				<i className='fa fa-question-circle'></i> Faites une recherche par ville
				ou code postal et le choisir
			</small>
			<div className='flex items-stretch w-full justify-between'>
				<input
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							getAddress();
						}
					}}
					defaultValue={[...currentAdress].join(', ')}
					type='text'
					{...register(element.name + '4', {
						...element.validation,
					})}
					className={`${style.simple__input} w-11/12`}
				/>
				<div className='w-1/12 flex justify-center items-center'>
					{!showLoading && (
						<i
							className='fa fa-search cursor-pointer p-4 rounded-lg bg-black/40'
							onClick={getAddress}></i>
					)}

					{showLoading && <ButtonSpinner showLoading={showLoading} />}
				</div>
			</div>
			{showChoices && (
				<div className='mt-0 z-5 h-auto overflow-y-auto  border w-full border-gray-300 text-white text-sm rounded-lg'>
					<ul className='w-full'>
						{searchResults.map((search, index) => (
							<li
								key={index}
								className={`p-5 cursor-pointer text-black hover:bg-[#666633] hover:text-white my-1 font-bold ${element.name}`}
								onClick={() => {
									setAddress(search.city);
								}}>
								{search.city} [{search.code}]
							</li>
						))}
					</ul>
				</div>
			)}
			<ul className='inline-flex gap-4'>
				{[...currentAdress].map((item, index) => (
					<li
						className='p-2 text-white rounded-md bg-gray-800 !bg-opacity-50'
						key={index}>
						{item}{' '}
						<i
							className='fa fa-times cursor-pointer'
							onClick={() => removeItem(item)}></i>
					</li>
				))}
			</ul>
			<input
				type='hidden'
				value={[...currentAdress].join(', ')}
				{...register('localities', { ...element.validation })}
				className={style.simple__input}
			/>
		</>
	);
};
