import { createHttp } from '../../http';

const http = createHttp();

const PUBLICATION_BASE_URL = '/publication';

export const useAnnonce = {
	createAnnonce: async (annonces) => await http.post('/chat-gpt', annonces),
};

export const usePublication = {
	retrievePortals: async () => http.get(`${PUBLICATION_BASE_URL}/portals`),
	postAd: async (uuid) => http.post(`${PUBLICATION_BASE_URL}/ads/${uuid}`),
	getAdPublications: async (adPublicationId) =>
		http.get(`${PUBLICATION_BASE_URL}/ad-publication/${adPublicationId}`),
	getPortal: async (portalId) =>
		http.get(`${PUBLICATION_BASE_URL}/portal/${portalId}`),

	publishOnPortal: async (publicationId, bienUuid) =>
		http.put(`${PUBLICATION_BASE_URL}/publish/${bienUuid}/${publicationId}`),

	exportOnWebsite: async (estateUuid) =>
		http.get(`${PUBLICATION_BASE_URL}/publish-website/${estateUuid}`),
};
