import { useEffect, useState } from 'react';
import { BlocCard } from '../../components/generals/card/bloc';
import { Breadcumbs } from '../../components/generals/headers/breadcumbs';
import {
	getStorageUser,
	updateStorageAgencyOnly,
} from '../../service/localStorage';
import { configService } from '../../service/api/config';
import { GeneralSpinner } from '../../components/commons/general-spinner';
import { imageAgent, imageBackend } from '../../helpers/image.source.';
import { Modal } from '../../components/commons/modal/modal';
import { AgencyService } from '../../service/agency/agency.service';
import { successToast } from '../../service/toast/showToast';
import { ButtonSpinner } from '../../components/commons/buttonSpinner';

export const Profile = () => {
	const user = getStorageUser().user;
	const [showForm, setShowForm] = useState(false);
	const [showFormApi, setShowFormApi] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [agency, setAgency] = useState();
	const [logo, setLogo] = useState();
	const [websiteLink, setWebsiteLink] = useState();
	const handleLogoChange = (e) => {
		setLogo(e.target.files[0]);
	};
	const uploadLogo = () => {
		setShowLoading(true);
		const form = new FormData();
		form.append('logo', logo);
		AgencyService.updateLogoAgency(form)
			.then(() => {
				configService.getConfiguration().then(({ data }) => {
					setAgency(data);
					updateStorageAgencyOnly(data);
					setShowForm(false);
					successToast('Modification du logo réussie avec succès');
				});
			})
			.finally(() => setShowLoading(false));
	};

	const saveConfig = async () => {
		setShowLoading(true);
		const data = { api_key: getStorageUser().token, website_link: websiteLink };
		AgencyService.updateConfigSiteweb(data)
			.then(() => {
				successToast('Configuration pour le siteweb réussi avec succès');
				setTimeout(window.location.reload(), 1500);
			})
			.finally(() => setShowLoading(false));
	};

	useEffect(() => {
		configService.getConfiguration().then(({ data }) => setAgency(data));
	}, []);
	return (
		(user && agency && (
			<>
				<Breadcumbs title={'Mon profil'} />
				<div className='border-2 border-solid border-black/40 w-full p-2'>
					<div className='flex flex-wrap justify-between'>
						<div className='w-1/3'>
							<div className='w-full h-[300px]'>
								<img
									src={
										user.photo_url
											? imageAgent(`/${user.agency_id}/${user.photo_url}`)
											: '/images/agents/8380015.jpg'
									}
									alt=''
									className='h-full object-contain mx-auto'
								/>
							</div>
						</div>
						<div className='w-2/3 p-2'>
							<div className='border-solid border-2 h-full p-2'>
								<h1 className='text-bold text-xl text-center p-4 blue-color text-white rounded  mb-4'>
									Information personnelle
								</h1>
								<div className='p-2'>
									<ul className='list-disc p-2'>
										<li>
											Nom : <span className='font-bold'>{user.name}</span>
										</li>
										<li>
											Email : <span className='font-bold'>{user.email}</span>
										</li>
										<li>
											Agence :{' '}
											<span className='font-bold'>{agency.nameAgency}</span>
										</li>
										<li>
											Compagnie :{' '}
											<span className='font-bold'>{agency.nameCompany}</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<hr className='my-4' />
					{parseInt(user.role) === 777 && (
						<>
							<h1 className='text-bold text-xl text-center p-4 blue-color text-white rounded  mb-4'>
								Mon agence
							</h1>
							<div className='flex flex-wrap justify-center items-center w-full gap-1 mt-5'>
								<BlocCard
									blocTitle={'Mes agents'}
									link={'users'}
									className={'w-full sm:w-1/5'}
								/>
								<BlocCard
									onClick={() => setShowForm(true)}
									blocTitle={'Mon logo'}
									className={'w-full sm:w-1/5'}
								/>

								<BlocCard
									onClick={() => setShowFormApi(true)}
									blocTitle={'Siteweb'}
									className={'w-full sm:w-1/5'}
								/>

								<BlocCard
									blocTitle={'Ma pige'}
									link={'pige'}
									className={'w-full sm:w-1/5'}
								/>
							</div>
						</>
					)}
				</div>

				<Modal
					modalTitle={"Clé d'API pour le siteweb"}
					isOpen={showFormApi}
					allowBackdrop={false}
					height='h-auto'
					width='w-1/2'
					handleClose={() => setShowFormApi(false)}>
					<div>
						Veuillez copier cet API key dans l'espace d'administration de votre
						siteweb pour pouvoir le relier avec votre logiciel.
						<br />
						<input
							value={getStorageUser().token}
							readOnly={true}
							type='text'
							className='bg-gray-400 p-4 my-2 w-full text-center font-bold'
						/>
						Le lien de votre siteweb.
						<br />
						<input
							type='text'
							className='bg-gray-400 p-4 my-2 w-full font-bold'
							onChange={(e) => setWebsiteLink(e.target.value)}
							defaultValue={agency.website}
						/>
						<small>
							Veuillez configurer correctement ce lien pour pouvoir lier votre
							siteweb et votre logiciel MAPIM Immo
						</small>
						<button
							className='default-color px-4 py-2 text-white font-bold capitalize flex items-center justify-center gap-2 rounded'
							disabled={showLoading}
							onClick={saveConfig}>
							Sauvegarder
						</button>
					</div>
				</Modal>
				<Modal
					modalTitle={'Modifier mon logo'}
					height='h-full'
					width='w-11/12 sm:w-2/3'
					isOpen={showForm}
					allowBackdrop={false}
					handleClose={() => setShowForm(false)}>
					<div>
						<h1 className='text-center'>Logo actuelle</h1>
						<img
							src={imageBackend(agency.agency_logo)}
							alt='Logo of agency'
							className='w-full h-[500px] object-cover mx-auto'
						/>
					</div>
					<div className='my-4'>
						<h1 className='text-center'>Changer de logo ?</h1>
						<div className='border border-solid w-full my-4 flex items-center justify-center'>
							<input
								type='file'
								className='w-full'
								onChange={handleLogoChange}
							/>
						</div>
						<button
							className='default-color px-4 py-2 text-white font-bold capitalize flex items-center justify-center gap-2'
							disabled={showLoading}
							onClick={uploadLogo}>
							Modifier{' '}
							<ButtonSpinner showLoading={showLoading} dimension='w-4 h-4' />
						</button>
					</div>
				</Modal>
			</>
		)) || <GeneralSpinner />
	);
};
