import { useState } from 'react';
import { Link } from 'react-router-dom';

export const AlertSection = ({ alert, removeAlert }) => {
	const [showResults, setShowResults] = useState(false);

	return (
		<>
			<div
				className={`w-full my-4 p-4 min-h-[10em] border-2 border-gray-400 relative`}>
				{alert.results.length && (
					<button
						className='absolute top-2 right-2 p-2 bg-green-500 rounded-full h-8 w-8 flex items-center justify-center text-white'
						onClick={() => setShowResults(!showResults)}>
						<i
							className={`${
								showResults ? 'fa fa-chevron-up' : 'fa fa-chevron-down'
							}`}></i>
					</button>
				)}
				<button
					className='absolute top-2 right-12 p-2 bg-red-500 rounded-full h-8 w-8 flex items-center justify-center text-white'
					onClick={() => removeAlert(alert.id)}>
					<i className={`fa fa-trash`}></i>
				</button>
				<button className='absolute top-2 left-2 p-2 bg-blue-300 rounded-full h-8 font-bold flex items-center justify-center text-white'>
					{alert.results.length} resultat(s)
				</button>

				<div
					className={`${
						showResults
							? 'sticky top-1 z-20 bg-black text-white'
							: 'relative text-gray-700'
					} shadow-xl border-2 border-gray-200 w-full p-4 mt-8 min-h-[7em]`}>
					<div className='flex items-stretch justify-between text-xl font-bold  px-16 flex-wrap gap-10'>
						<span className='w-1/4'>
							<i className='fa fa-question-circle'></i> Type : {alert.type}
						</span>
						<span className='w-1/4'>
							<i className='fa fa-question-circle'></i> Bien : {alert.bien}
						</span>
						<span className='w-1/4'>
							<i className='fa fa-question-circle'></i> Codes postaux :{' '}
							{JSON.parse(alert.cp).join(',')}
						</span>

						{alert.max_surf && (
							<span className='w-1/4'>
								<i className='fa fa-question-circle'></i> Surface max. :{' '}
								{alert.max_surf} <sup>2</sup>
							</span>
						)}
						{alert.min_surf && (
							<span className='w-1/4'>
								<i className='fa fa-question-circle'></i> Surface min. :{' '}
								{alert.min_surf} m<sup>2</sup>
							</span>
						)}
						{alert.max_pieces && (
							<span className='w-1/4'>
								<i className='fa fa-question-circle'></i> Pièces max. :{' '}
								{alert.max_pieces}
							</span>
						)}
						{alert.min_pieces && (
							<span className='w-1/4'>
								<i className='fa fa-question-circle'></i> Pièces min. :{' '}
								{alert.min_pieces}
							</span>
						)}
					</div>
				</div>
				{showResults && (
					<div className='w-full'>
						{alert.results.map((item, index) => (
							<div
								key={index}
								className='w-full flex items-stretch justify-between my-4 border border-gray-300 p-4'>
								{/* {JSON.stringify(item.pige.img)} */}

								<img src={item.pige.img} className='w-1/3 h-64' alt='' />
								<div className='w-2/3 p-4 break-words'>
									<h1 className='font-bold'>
										<a href={`/ma-pige/details/${item.pige.id}`}>
											{item.pige.titre} - {item.pige.type}
										</a>
									</h1>
									<br />
									{item.pige.texte}
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</>
	);
};
