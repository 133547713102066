import {
	coverImageFromProxy,
	slideImageFromProxy
} from '../../../../helpers/image.source.';
import { Dpe } from '../dpe/Dpe';
import { Pricing } from '../dpe/Pricing';
import { IS_APPART, TYPE_OFFRE } from '../../../../helpers/forms/type';
import { DpeSmall } from '../dpe/DpeSmall';

import { useEffect, useState } from 'react';

export const Model4 = ({ bien, is_downloading }) => {
	return (
		<>
			<div
				className='relative border-2 border-solid'
				style={{
					minHeight: '750px'
				}}>
				<img
					src={`${coverImageFromProxy(bien.photos.photos_couvert[0].photo)}`}
					alt=''
					className='w-full'
				/>

				{bien.photos.photos_slide.length != 0 && (
					<div className='text-white w-1/3 absolute top-0 right-0 bg-white p-[5px] flex items-center justify-center'>
						<img
							src={`${slideImageFromProxy(bien.photos.photos_slide[0].photo)}`}
							className='w-full h-auto'
							alt=''
						/>
					</div>
				)}

				<div className='w-4/5 absolute bottom-10 right-0 border border-gray z-20 flex items-stretch justify-center text-sm'>
					<div className='w-2/5 bg-[#343338]/60 px-4 py-4'>
						<h1 className='font-bold text-white'>
							{bien.advertisement.title} - {bien.sector.public_country} (
							{bien.sector.public_zap})
						</h1>
						<div className='text-white mt-10'>
							<Pricing bien={bien} />
						</div>
					</div>
					<div className='w-3/5 bg-white/70 p-1 flex items-start gap-8 flex-wrap justify-between pt-6'>
						<div className='flex flex-wrap items-center gap-8 ml-4'>
							<small className='text-xl font-bold rounded flex gap-1 items-center justify-center'>
								<img className='w-10' src='/images/impressions/measure.png' />
								{bien.land_area} m<sup>2</sup>
							</small>
							{IS_APPART.includes(
								bien.classification_estate.designation.toLowerCase()
							) && (
								<>
									<small className='text-xl font-bold rounded flex gap-1 items-center justify-center'>
										<img
											className='w-10'
											src='/images/impressions/king-size.png'
										/>
										{bien.number_bedroom}
									</small>
									{bien.interior_detail?.nbOfBathroom && (
										<small className='text-xl font-bold rounded flex gap-1 items-center justify-center'>
											<img
												className='w-10'
												src='/images/impressions/shower.png'
											/>
											{bien.interior_detail.nbOfBathroom}
										</small>
									)}
								</>
							)}
						</div>
						<div className='w-1/3 flex items-center justify-center'>
							<Dpe4 bien={bien} is_downloading={is_downloading} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const DPE_COLOR = {
	A: { class: 'mt-[0%]', name: 'A', downloading_class: 'mt-[5%]' },
	B: { class: 'mt-[3.5%]', name: 'B', downloading_class: 'mt-[9%]' },
	C: { class: 'pt-[8.5%]', name: 'C', downloading_class: 'pt-[16%]' },
	D: { class: 'mt-[20.5%]', name: 'D', downloading_class: 'mt-[25%]' },
	E: { class: 'mt-[28.5%]', name: 'E', downloading_class: 'mt-[32%]' },
	F: { class: 'mt-[36%]', name: 'F', downloading_class: 'mt-[40%]' },
	G: { class: 'mt-[44.5%]', name: 'G', downloading_class: 'mt-[48%]' }
};

const Dpe4 = ({ bien, is_downloading }) => {
	const calculateDPE = () => {
		const consommation = parseInt(bien.diagnostic.dpe_consommation);
		const ges = parseInt(bien.diagnostic.dpe_ges);

		if (0 <= ges && 6 >= ges && consommation <= 69 && 0 <= consommation) {
			return DPE_COLOR.A;
		}
		if (7 <= ges && 10 >= ges && consommation <= 109 && 70 <= consommation) {
			return DPE_COLOR.B;
		}
		if (11 <= ges && 29 >= ges && consommation <= 179 && 110 <= consommation) {
			return DPE_COLOR.C;
		}
		if (30 <= ges && 49 >= ges && consommation <= 249 && 180 <= consommation) {
			return DPE_COLOR.D;
		}
		if (50 <= ges && 69 >= ges && consommation <= 329 && 250 <= consommation) {
			return DPE_COLOR.E;
		}
		if (70 <= ges && 99 >= ges && consommation <= 429 && 330 <= consommation) {
			return DPE_COLOR.F;
		}
		if (100 <= ges && 430 <= consommation) {
			return DPE_COLOR.G;
		}
	};

	const [currentGes, setCurrentGes] = useState();

	useEffect(() => {
		const ges = calculateDPE();
		setCurrentGes(ges);
	}, []);

	return (
		<>
			{(bien.diagnostic.dpes === 'DPE Vièrge' && (
				<img
					className='w-1/3'
					src='/images/logo_DPE_vierge.png'
					alt='DPE Vierge'
				/>
			)) ||
				(currentGes && (
					<>
						{(is_downloading && (
							<div
								className='w-full flex items-stretch min-h-[120px]'
								style={{
									backgroundSize: '100%',
									backgroundPosition: '0px 0',
									backgroundRepeat: 'no-repeat',
									backgroundImage: `url('/images/DPE/${currentGes.name}.png')`
								}}>
								<div
									className={`${currentGes.class} pl-3 flex  ${
										bien.diagnostic.dpe_ges < 10 ? 'gap-[1.8em]' : 'gap-[1em]'
									} items-start text-[10px]`}>
									<div>{bien.diagnostic.dpe_consommation}</div>
									<div>{bien.diagnostic.dpe_ges}</div>
								</div>
							</div>
						)) || (
							<div
								className='w-full flex items-stretch min-h-[140px]'
								style={{
									backgroundSize: '100%',
									backgroundPosition: '0px 0',
									backgroundRepeat: 'no-repeat',
									backgroundImage: `url('/images/DPE/${currentGes.name}.png')`
								}}>
								<div
									className={`${currentGes.downloading_class} pl-3 flex ${
										bien.diagnostic.dpe_ges < 10 ? 'gap-[1.8em]' : 'gap-[1em]'
									} items-start text-sm`}>
									<div>{bien.diagnostic.dpe_consommation}</div>
									<div>{bien.diagnostic.dpe_ges}</div>
								</div>
							</div>
						)}
					</>
				))}
		</>
	);
};
