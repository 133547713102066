import { requiredField } from '../../../validation/validation';
export const TypeBienContact = {
	//Type de bien
	type: 'radio',
	name: 'type_estate_id',
	className: 'w-full ',
	title: 'Type de bien(s) :',
	tagName: 'preferency.type_estate_id',
	choices: [
		{
			title: 'Maison',
			value: '1',
			visible: [
				{
					condition: 'EQUAL',
					field: 'type_offert_id',
					value: ['1', '2', '3'],
				},
			],
		},
		{
			title: 'Appartement',
			value: '2',
			visible: [
				{
					condition: 'EQUAL',
					field: 'type_offert_id',
					value: ['1', '2', '3'],
				},
			],
		},
		{
			title: 'Cession',
			value: '5',
			visible: [
				{
					condition: 'EQUAL',
					field: 'type_offert_id',
					value: ['4'],
				},
			],
		},
		{
			title: 'Fonds de commerce',
			value: '6',
			visible: [
				{
					condition: 'EQUAL',
					field: 'type_offert_id',
					value: ['4'],
				},
			],
		},
		{
			title: 'Terrain',
			value: '3',
			visible: [
				{
					condition: 'EQUAL',
					field: 'type_offert_id',
					value: ['1', '2', '4'],
				},
			],
		},

		{
			title: 'Autres',
			value: '4',
			visible: [
				{
					condition: 'EQUAL',
					field: 'type_offert_id',
					value: ['1', '2', '3', '4'],
				},
			],
		},
	],
};
