import { requiredField } from '../../../validation/validation';
import {
	CRITERIA_IN_NUMBER,
	TYPE_BIEN,
	TYPE_OFFRE,
	TYPE_OFFRE_SUITE
} from '../../type';

export const InteriorDetail = [
	{
		//Catégorie
		group: 'info_personnelle',
		type: 'select',
		name: 'category',
		className: 'w-full ',
		tagName: 'interior_detail.category.category',
		title: 'Catégorie  :',
		choices: [
			{
				title: 'Classique',
				value: 'Classique',
				visible: [
					{
						condition: 'EQUAL',
						field: TYPE_OFFRE.formName,
						value: [TYPE_OFFRE.VENTE]
					},
					{
						condition: 'EQUAL',
						field: TYPE_OFFRE_SUITE.formName,
						value: [TYPE_OFFRE_SUITE.CLASSIQUE]
					},
					{
						condition: 'EQUAL',
						field: TYPE_BIEN.formName,
						value: [TYPE_BIEN.MAISON]
					}
				]
			},
			{
				title: 'Famille',
				value: 'Famille',
				visible: [
					{
						condition: 'INEQUAL',
						field: 'type_offert_id',
						value: ['1']
					}
				]
			},
			{
				title: 'Primo-accident',
				value: 'Primo-accident'
			},
			{
				title: 'Investissement',
				value: 'Investissement'
			},
			{
				title: 'Plain-pied',
				value: 'Plain-pied'
			},
			{
				title: 'Prestige',
				value: 'Prestige'
			},
			{
				title: 'Seul / Couple',
				value: 'Seul / Couple',
				visible: [
					{
						condition: 'EQUAL',
						field: TYPE_OFFRE.formName,
						value: [TYPE_OFFRE.LOCATION]
					},
					{
						condition: 'EQUAL',
						field: TYPE_OFFRE_SUITE.formName,
						value: [TYPE_OFFRE_SUITE.SAISONNIERE]
					}
				]
			}
		],

		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{
				condition: 'EQUAL',
				field: 'type_estate_id',
				value: [
					TYPE_BIEN.MAISON,
					TYPE_BIEN.APPARTEMENT,
					TYPE_BIEN.TERRAIN,
					TYPE_BIEN.AUTRES
				]
			}
		]
	},
	{
		//Nb de pièces
		type: 'number',
		title: 'Nombres de couchages :',
		className: 'w-1/2',
		name: 'nbOfSleeping',
		tagName: 'interior_detail.nbOfSleeping',
		validation: {
			validate: { requiredField }
		},
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['4'] },
			{ condition: 'EQUAL', field: 'criteria', value: ['immeuble'] }
		]
	},
	{
		//Nb de lots
		type: 'number',
		title: 'Nombres de lots :',
		className: 'w-1/2',
		name: 'nbOfLots',
		tagName: 'interior_detail.nbOfLots',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['3'] },
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] }
		]
	},

	{
		//Nb de bain
		type: 'number',
		title: 'Nombres de salle(s) de bain(s) :',
		className: 'w-1/2',
		validation: {
			validate: { requiredField }
		},
		name: 'nbOfBathroom',
		tagName: 'interior_detail.nbOfBathroom',
		visible: [
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Nb de eau
		type: 'number',
		title: "Nombres de salle(s) d'eau :",
		className: 'w-1/2',
		name: 'nbOfRoomWater',
		tagName: 'interior_detail.nbOfRoomWater',
		validation: {
			validate: { requiredField }
		},
		visible: [
			{
				condition: 'EQUAL',
				field: 'type_offert_id',
				value: ['1', TYPE_OFFRE.LOCATION]
			},
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},

	// {
	// 	//Nb de WC
	// 	type: 'number',
	// 	title: 'Nombres de WC :',
	// 	className: 'w-1/2',
	// 	validation: {
	// 		validate: { requiredField },
	// 	},
	// 	name: 'nbOfWc',
	// 	tagName: 'interior_detail.nbOfWc',
	// 	visible: [
	// 		{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
	// 		{
	// 			condition: 'EQUAL',
	// 			field: 'type_offer_suite_id',
	// 			value: ['1', '2', '3'],
	// 		},
	// 		{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
	// 		{
	// 			condition: 'INEQUAL',
	// 			field: 'criteria',
	// 			value: [CRITERIA_IN_NUMBER.CAVE,CRITERIA_IN_NUMBER.GARAGE,CRITERIA_IN_NUMBER.PARKING],
	// 		},
	// 	],
	// },

	{
		//Meubles
		type: 'radio',
		name: 'furniture',
		tagName: 'interior_detail.caracteristique.furniture',
		className: 'w-full ',
		title: 'Meubles:',
		validation: {
			validate: { requiredField }
		},
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},

	{
		//Surface
		group: 'info_personnelle',
		title: 'Surface',
		className: 'w-full sm:w-2/12 !justify-start mt-4 sm:pl-4',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		// surface
		//Carrez
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'number',
		title: '  Carrez :',
		validation: {
			validate: { requiredField }
		},
		name: 'surfaceSquare',
		tagName: 'interior_detail.surfaceSquare',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		// surface
		//Sejour
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'number',
		validation: {
			validate: { requiredField }
		},
		title: 'Surfaces Sejour :',
		name: 'surfaceStay',
		tagName: 'interior_detail.surfaceStay',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//boutin
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'number',
		validation: {
			validate: { requiredField }
		},
		title: 'Surfaces boutin :',
		name: 'surfaceBoutin',
		tagName: 'interior_detail.surfaceBoutin',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//cuisine
		group: 'info_personnelle',
		title: 'Cuisine',
		className: 'w-full sm:w-2/12 !justify-start mt-4 sm:pl-4',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},

	{
		// cuisine
		//Type
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'select',
		validation: {
			validate: { requiredField }
		},
		title: 'Type',
		name: 'TypeOfKitchen',
		tagName: 'interior_detail.TypeOfKitchen',
		choices: [
			{
				title: 'Non defini',
				value: 'Non defini'
			},
			{
				title: 'Américaine',
				value: 'Américaine'
			},
			{
				title: 'Kitchenette',
				value: 'Kitchenette'
			},
			{
				title: 'Séparée',
				value: 'Séparée'
			},
			{
				title: 'Sans',
				value: 'Sans'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		// cuisine
		//Etat
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'select',
		title: 'Etat de cuisine:',
		name: 'StateOfKitchen',
		tagName: 'interior_detail.StateOfKitchen',
		choices: [
			{
				title: 'Non defini',
				value: 'Non defini'
			},
			{
				title: 'Equipée',
				value: 'Équipée'
			},
			{
				title: 'Semi-équipée',
				value: 'Semi-équipée'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Equipement cuisine
		group: 'info_personnelle',
		title: 'Equipement Cuisine',
		className: 'w-full sm:w-2/12 !justify-start mt-4 sm:pl-4',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['3'] },
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Plaque cuisson
		type: 'radio',
		name: 'hotplate',
		tagName: 'interior_detail.kitchenEquipment.hotplate',
		className: 'w-full sm:w-1/2 ',
		title: 'Plaque cuisson:',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Four
		type: 'radio',
		name: 'oven',
		tagName: 'interior_detail.kitchenEquipment.oven',
		className: 'w-full sm:w-1/2 ',
		title: 'Four:',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Micro-onde
		type: 'radio',
		name: 'microwave',
		tagName: 'interior_detail.kitchenEquipment.microwave',
		className: 'w-full sm:w-1/2 ',
		title: 'Micro-onde:',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Congélateur
		type: 'radio',
		name: 'freezer',
		tagName: 'interior_detail.kitchenEquipment.freezer',
		className: 'w-full sm:w-1/2 ',
		title: 'Congélateur:',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//refrigerateur
		type: 'radio',
		name: 'fridge',
		tagName: 'interior_detail.kitchenEquipment.fridge',
		className: 'w-full sm:w-1/2 ',
		title: 'Réfrigérateur:',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//lave-vaiselle
		type: 'radio',
		name: 'dishwasher',
		tagName: 'interior_detail.kitchenEquipment.dishwasher',
		className: 'w-full sm:w-1/2 ',
		title: 'Lave-vaisselle:',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//cafetière/nespresso
		type: 'radio',
		name: 'coffeeMaker',
		tagName: 'interior_detail.kitchenEquipment.coffeeMaker',
		className: 'w-full sm:w-1/2 ',
		title: 'Cafetière/Nespresso:',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//grille-pain
		type: 'radio',
		name: 'toaster',
		tagName: 'interior_detail.kitchenEquipment.toaster',
		className: 'w-full sm:w-1/2 ',
		title: 'Grille-pain:',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Multimédia
		group: 'info_personnelle',
		title: 'Multimédia',
		className: 'w-full sm:w-2/12 !justify-start mt-4 sm:pl-4',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//TV
		type: 'radio',
		name: 'tv',
		tagName: 'interior_detail.multimedia.tv',
		className: 'w-full sm:w-1/2 ',
		title: 'TV:',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Internet
		type: 'radio',
		name: 'internet',
		tagName: 'interior_detail.multimedia.internet',
		className: 'w-full sm:w-1/2 ',
		title: 'Internet:',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Hifi
		type: 'radio',
		name: 'hifi',
		tagName: 'interior_detail.multimedia.hifi',
		className: 'w-full sm:w-1/2 ',
		title: 'Hifi:',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Lave-linge
		type: 'radio',
		name: 'washingMachine',
		tagName: 'interior_detail.othersEquipment.washingMachine',
		className: 'w-full sm:w-1/2 ',
		title: 'Lave-linge:',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//sèche-ligne
		type: 'radio',
		name: 'dryer',
		tagName: 'interior_detail.othersEquipment.dryer',
		className: 'w-full sm:w-1/2 ',
		title: 'Sèche-linge:',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//fer à repasser
		type: 'radio',
		name: 'iron',
		tagName: 'interior_detail.othersEquipment.iron',
		className: 'w-full sm:w-1/2 ',
		title: 'Fer à repasser:',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	},
	{
		//Equipements Bébé
		type: 'radio',
		name: 'babyEquipment',
		tagName: 'interior_detail.othersEquipment.babyEquipment',
		className: 'w-full sm:w-1/2 ',
		title: 'Equipements Bébé:',
		choices: [
			{
				title: 'Non précisé',
				value: '1'
			},
			{
				title: 'Non',
				value: '2'
			},
			{
				title: 'Oui',
				value: '3'
			}
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3']
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: [
					CRITERIA_IN_NUMBER.CAVE,
					CRITERIA_IN_NUMBER.GARAGE,
					CRITERIA_IN_NUMBER.PARKING
				]
			}
		]
	}
];
