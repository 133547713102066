import { RangeInput } from '../../../components/commons/form/range';
import DetailsSection from '../../../components/generals/biens/section/DetailsSection';
import ContactDropdown from '../../../components/generals/card/contactDropdown';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Input } from '../../../components/commons/input';
import { useEffect, useState } from 'react';
import { imageBien } from '../../../helpers/image.source.';
import { VisiteSection } from '../../../components/generals/biens/section/Visite.section';
import { Button } from '../../../components/commons/button';
import { Modal } from '../../../components/commons/modal/modal';
import { useContact } from '../../../service/contact/useContact';
import { ContactDisplayName } from '../../../components/generals/contact/filter/ContactDisplayName';
import { useBien } from '../../../service/bien/useBien';
import { GeneralSpinner } from '../../../components/commons/general-spinner';
import { successToast } from '../../../service/toast/showToast';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, FreeMode } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/bundle';

const Statut = ({ bien }) => {
	const sold = bien.solds;
	const type_offert = bien.type_offert_id;
	const [statut, setStatut] = useState();

	useEffect(() => {
		if (sold === 'sold' || sold === 'archive') {
			type_offert === 1 ? setStatut('vendu') : setStatut('loué');
		} else {
			type_offert === 1 ? setStatut('à vendre') : setStatut('à louer');
		}
	}, [statut]);

	return (
		<>
			<span className='capitalize'>{statut}</span>
		</>
	);
};
export const BienInfoSection = ({ bien }) => {
	const navigate = useNavigate();
	const [contacts, setContacts] = useState([]);
	const [selectedContacts, setSelectedContacts] = useState([]);
	const [externalEmails, setExternalEmails] = useState();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		useContact
			.allContact(null, `paginated=false`)
			.then(({ data }) => setContacts(data));
	}, []);

	const [showSendEmailModal, setShowSendEmailModal] = useState(false);
	const sendEstatetoMail = () => {
		let data = [...selectedContacts];
		if (externalEmails) {
			data.push(...externalEmails.split(','));
		}

		setLoading(true);

		useBien
			.sendToMail({
				contacts: data,
				bien: bien
			})
			.then(() => {
				setShowSendEmailModal(false);
				successToast('Bien envoyé vers les emails mentionnés');
			})
			.finally(() => setLoading(false));
	};
	const printEstate = () => {
		console.log(bien);
	};

	return (
		<>
			<div className='px-5 py-1 mt-5 blue-color flex  items-center justify-between'>
				<p className='text-white'>Info sur le bien</p>
				<button className='text-white underline' onClick={() => navigate(-1)}>
					Retour
				</button>
			</div>

			<DetailsSection bien={bien}>
				<div className='grid grid-row md:grid-cols-2 gap-5 '>
					<div className=' grid gap-5  '>
						<div className='flex  items-center justify-between  '>
							<p className='text-[#2d5c6a] w-1/4'>Annonce :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 '>
								{bien?.advertisement.title}
							</div>
						</div>

						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4 '>Infos :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 break-words'>
								{bien?.advertisement.description}
							</div>
						</div>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Lien :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-auto text-blue-400'>
								{bien.estate_link && (
									<a href={bien.estate_link} target='_blank'>
										{bien.estate_link}
									</a>
								)}
							</div>
						</div>

						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Diffusion :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12 flex items-center justify-start'>
								<div
									title='Exportation sur votre siteweb'
									className={`border-2 border-solid border-gray-600 ${
										bien.website_exported ? 'bg-green-600' : 'bg-red-600'
									} bg-green-600 text-white font-bold rounded-full cursor-pointer flex items-center justify-center w-auto h-8 px-2 gap-1`}>
									<i
										className={`${
											(bien.website_exported && 'fa fa-check-circle') ||
											'fa fa-times-circle'
										}`}></i>
									Site web
								</div>
							</div>
						</div>
					</div>

					<div className=' grid gap-5'>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>
								{' '}
								{bien.type_offert_id === 1 ? 'Prix' : 'Loyer'} :
							</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								{bien.type_offert_id === 1 ? bien?.publish_price : bien.rent} €
							</div>
						</div>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Honoraires :</p>
							<div className='w-[70%] mx-auto border border-[rgb(45,92,106)] rounded p-3 h-12'>
								{bien?.info_financiere?.honoraire}
							</div>
						</div>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Montant :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								{JSON.parse(bien.info_financiere?.info_tenant_chare).rising
									? JSON.parse(bien.info_financiere?.info_tenant_chare).rising +
									  ' €'
									: ''}
							</div>
						</div>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Négo :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								{bien.agent?.name}
							</div>
						</div>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Agence :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								{bien.agency && bien.agency?.nameAgency}
							</div>
						</div>{' '}
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>N° Mandat :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-auto'>
								{bien.mandate && (
									<Button
										type={'button'}
										onClick={() => {
											if (bien.mandate.contact.uuid) {
												navigate(
													`/mes-contacts/details-contact/${bien.mandate.contact.uuid}`
												);
											}
										}}
										className={`blue-color-i rounded-xl`}>
										{bien.mandate?.num_mandat}
									</Button>
								)}
							</div>
						</div>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Statut :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								<Statut bien={bien} />
							</div>
						</div>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Action :</p>
							<div className='w-[70%] mx-auto rounded h-12 flex items-center gap-4'>
								<Button
									type={'button'}
									onClick={() => setShowSendEmailModal(true)}
									className={`blue-color-i rounded-xl`}>
									Envoyer par email
								</Button>

								<Button
									type={'button'}
									onClick={printEstate}
									className={`blue-color-i rounded-xl`}>
									Imprimer
								</Button>
							</div>
						</div>
					</div>
				</div>

				<Modal
					isOpen={showSendEmailModal}
					modalTitle={'Envoyer le bien par mail'}
					allowBackdrop={false}
					allowForceClose={true}
					handleClose={() => setShowSendEmailModal(false)}
					id={'send-to-mail-modal'}>
					<div>
						<h1>
							Choisissez parmis les contacts ci-dessous pour l'envoyer, ou bien
							entrer les mails externes dans le champ, separés par une{' '}
							<b>virgule</b>.
						</h1>

						<div className='flex flex-wrap gap-x-36 h-96 overflow-y-scroll my-8'>
							{contacts.map((contact, index) => {
								return (
									<div
										key={index}
										className='flex items-center justify-start gap-2 font-bold text-gray-500 w-full'>
										<input
											type='checkbox'
											onChange={({ target }) =>
												target.checked
													? setSelectedContacts([
															...selectedContacts.filter(
																(item) => item.id_contact != contact.id_contact
															),
															contact
													  ])
													: setSelectedContacts([
															...selectedContacts.filter(
																(item) => item.id_contact != contact.id_contact
															)
													  ])
											}
											id=''
										/>
										<label>
											<ContactDisplayName contact={contact} />
										</label>
									</div>
								);
							})}
						</div>
						<div>
							<label className='w-full'>Email(s) externe(s) :</label>
							<input
								type='text'
								name='externalEmail'
								className='w-full my-2 border border-gray-400 p-2'
								onChange={({ target }) => setExternalEmails(target.value)}
							/>
						</div>
						<button
							className='default-color px-8 py-2 text-white rounded'
							onClick={sendEstatetoMail}>
							Envoyer
						</button>
					</div>
				</Modal>
			</DetailsSection>
			{bien.photos && (
				<div className='p-4'>
					<Swiper
						modules={[Autoplay, Navigation, Pagination, FreeMode]}
						spaceBetween={50}
						slidesPerView={3}
						autoplay={{
							delay: 2000,
							pauseOnMouseEnter: true
						}}
						navigation={{
							enabled: true
						}}
						loop={true}>
						{bien.photos.photos_slide.map((image, index) => (
							<SwiperSlide key={index}>
								<img
									className=' w-96 object-contain rounded-t-xl h-64 cursor-pointer'
									src={imageBien(encodeURI(`photos_slide${image.photo}`))}
									alt={image.description}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			)}

			<VisiteSection visites={bien.compte_rendu} />

			{loading && <GeneralSpinner />}
		</>
	);
};
