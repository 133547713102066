import React, { useState } from 'react';
import { contactForm } from '../../helpers/forms/contact';
import { useContact } from '../../service/contact/useContact';
import { CustomFormBuilder } from '../../components/commons/form/CustomFormBuilder';
import {
	successToast,
	technicalErrorToast
} from '../../service/toast/showToast';
import { GeneralSpinner } from '../../components/commons/general-spinner';

export default function AddContactPage() {
	const [isLoading, setIsLoading] = useState(false);
	const handleSubmit = async (data) => {
		let newContact = {};

		newContact.contact = {
			contact_type: data.contact_type,
			target: data.target,
			civility: data.civility,
			firstname: data.firstname,
			lastname: data.lastname,
			legal_form: data.legal_form,
			company_name: data.company_name,
			siret: data.siret,
			phone: data.phone,
			home_phone: data.home_phone,
			mail: data.mail,
			country_contact: data.country_contact,
			city_contact: data.city_contact,
			zip_contact: data.zip_contact,
			adress_contact: data.adress_contact,
			negociator: parseInt(data.negociator),
			contact_source: data.contact_source,
			note: data.note,
			space_perso_activate: data.space_perso_activate,
			space_proprio_activate: data.space_proprio_activate,
			man_info: {
				lastname: data.manLastname,
				firstname: data.manFirstname,
				phone: data.phone,
				mail: data.mail
			},
			woman_info: {
				lastname: data.womanLastname,
				firstname: data.womanFirstname,
				phone: data.womanPhonenumber,
				mail: data.womanEmail
			},
			man_info_compl: {
				country: data.manCountry,
				city: data.manCity,
				zip: data.manZip,
				adress: data.manAdress,
				note: data.manNote
			},
			woman_info_compl: {
				country: data.womanCountry,
				city: data.womanCity,
				zip: data.womanZip,
				adress: data.womanAdress,
				note: data.womanNote
			}
		};
		newContact.preferencyContact = {
			criteria: data.criteria,
			min_budgets: data.min_budgets,
			max_budgets: data.max_budgets,
			min_surface: data.min_surface,
			max_surface: data.max_surface,
			min_room: data.min_room,
			max_room: data.max_room,
			min_surface_ground: data.min_surface_ground,
			max_surface_ground: data.max_surface_ground,
			min_bedroom: data.min_bedroom,
			max_bedroom: data.max_bedroom,
			localities: data.localities,
			start_date: data.start_date,
			end_date: data.end_date,
			night_number: data.night_number,
			number_level: data.number_level,
			number_wc: data.number_wc,
			garden_exist: data.garden_exist,
			garden_exist_area: data.garden_exist_area,
			land_area: data.land_area,
			swim: data.swim,
			swim_exist_area: data.swim_exist_area,
			garage_number: data.garage_number,
			indoor_parking: data.indoor_parking,
			outdoor_parking: data.outdoor_parking,
			exhibition: data.exhibition,
			view: data.view,
			attic: data.attic,
			cellar: data.cellar,
			furnished: data.furnished,
			carrez_area: data.carrez_area,
			stay_area: data.stay_area,
			common_ownership: data.common_ownership,
			basement: data.basement,
			cave: data.cave,
			teracce: data.teracce,
			logia: data.logia,
			ground_floor: data.ground_floor,
			balcony: data.balcony,
			varangue: data.varangue,
			veranda: data.veranda,
			heating_format: data.heating_format,
			heating_type: data.heating_type,
			heating_energy: data.heating_energy,
			elevator: data.elevator,
			air_conditionning: data.air_conditionning,
			fireplace: data.fireplace,
			glazing: data.glazing,
			ground_surface: data.ground_surface,
			poolable: data.poolable,
			wooded: data.wooded,
			divisible: data.divisible,
			garden_exist_private: data.garden_exist_private,
			swim_exist_statut: data.swim_exist_statut,
			swim_exist_nature: data.swim_exist_nature,
			basement_amenaged: data.basement_amenaged,
			cave_area: data.cave_area,
			balcony_area: data.balcony_area,
			teracce_area: data.teracce_area,
			veranda_area: data.veranda_area,
			type_offert_id: parseInt(data.type_offert_id),
			type_estate_id: parseInt(data.type_estate_id),
			parking: parseInt(data.parking),
			number_cave: parseInt(data.number_cave),
			alimentation: data.alimentation,
			acces_handicapes: data.acces_handicapes,
			local_velos: data.local_velos,
			gardien: data.gardien,
			porte_blinde: data.porte_blinde,
			interphone: data.interphone,
			visiophone: data.visiophone,
			alarme: data.alarme,
			digicode: data.digicode,
			nbOfSleeping: parseInt(data.nbOfSleeping) || 0,
			nbOfLots: parseInt(data.nbOfLots) || 0,
			nbOfBathroom: parseInt(data.nbOfBathroom) || 0,
			nbOfRoomWater: parseInt(data.nbOfRoomWater) || 0,
			formatResidence: data.formatResidence,
			typeResidence: data.typeResidence,
			water: data.water,
			gaz: data.gaz,
			elec: data.elec,
			tel: data.tel
		};

		if (data.mandat_edition)
			newContact.mandate = {
				num_mandat: data.num_mandat,
				person: data.person,
				bien_id_bien: data.bien_id_bien === 0 ? '' : data.bien_id_bien
			};

		try {
			setIsLoading(true);

			await useContact.createContact(newContact).then((response) => {
				successToast(response.data.message);
				setTimeout(() => (window.location.href = '/mes-contacts'), 2000);
			});
		} catch (error) {
			technicalErrorToast();
			setIsLoading(false);
		}
	};

	return (
		<div className='border-2 border-solid border-[#2d5c6a]'>
			<CustomFormBuilder onComplete={handleSubmit} form={contactForm} />
			{isLoading && <GeneralSpinner />}
		</div>
	);
}
