import { RangeInput } from '../../../components/commons/form/range';
import DetailsSection from '../../../components/generals/biens/section/DetailsSection';
import ContactDropdown from '../../../components/generals/card/contactDropdown';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Input } from '../../../components/commons/input';
import './BienRapprochement.css';
import { useEffect, useState } from 'react';
import { GeneralSpinner } from '../../../components/commons/general-spinner';

export const BienRapprochementSection = ({ bien }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [bienRapprochment, setBienRapprochment] = useState(
		bien.rapprochements
			.sort((a, b) => b.value - a.value)
			.filter((item) =>
				bien.mandate?.contact
					? item?.contact?.id_contact != bien.mandate?.contact?.id_contact
					: true
			)
			.filter(
				(rapprochement) =>
					rapprochement.contact.preferency.type_offert_id == bien.type_offert_id
			)
	);
	const updateRapprochement = (rapprochmentPercent) => {
		setLoading(true);
		const [minRapprochment, maxRapprochment] = rapprochmentPercent;
		const rapprochement = bienRapprochment
			.filter(
				(elem) => elem.value >= minRapprochment && elem.value <= maxRapprochment
			)
			.filter(
				(rapprochement) =>
					rapprochement.contact.preferency.type_offert_id == bien.type_offert_id
			);
		setBienRapprochment(rapprochement);
		setTimeout(() => setLoading(false), 700);
	};

	useEffect(() => {}, [bienRapprochment]);

	return (
		<>
			<div className='px-5 py-1 mt-5 blue-color flex items-center justify-between'>
				<p className='text-white'>Gestion des rapprochements</p>
				<button className='text-white underline' onClick={() => navigate(-1)}>
					Retour
				</button>
			</div>

			<DetailsSection bien={bien}>
				<div className='flex my-4 items-center gap-4'>
					<p className='text-[#2d5c6a]'>Rapprochement à traiter :</p>
					<div className='text-white rounded-full w-8 h-8 flex items-center justify-center bg-green-600 '>
						{bienRapprochment.length}
					</div>
				</div>
				{/* <div className='flex my-4 gap-4'>
					<p className='text-[#2d5c6a]'>Négociateur :</p>
					<div>
						<Input
							type={'text'}
							name={'nameCompany'}
							className={'w-full h-8 border-2 border-solid '}
						/>
					</div>
				</div> */}

				<div className='flex my-4 flex-wrap gap-4'>
					<p className='text-[#2d5c6a] w-full'>% de correspondance :</p>
					<div className='twailind-progress-bar w-full'>
						<RangeInput
							min={0}
							max={100}
							textForShow={''}
							handleChange={updateRapprochement}
						/>
					</div>
				</div>
				<div className='grid grid-cols-10 mt-10'>
					<div className='col-span-4'></div>
					<div className='col-span-2'>Créer le</div>
					<div className='col-span-2'>Téléphone</div>
					<div className='col-span-2'>Correspondance</div>
				</div>

				{bienRapprochment.map((rapprochement) => {
					return (
						rapprochement?.contact?.id_contact !=
							bien.mandate?.contact?.id_contact && (
							<ContactDropdown
								key={rapprochement.id}
								rapprochement={rapprochement}
								bien={bien}
							/>
						)
					);
				})}
			</DetailsSection>
			{loading && <GeneralSpinner />}
		</>
	);
};
