export const FIELD_TYPE = {
	RADIO: 'radio',
	CHECKBOX: 'checkbox',
	TEXT: 'text',
};

export const CONDITION = {
	EQUAL: 'EQUAL',
	INEQUAL: 'INEQUAL',
};
