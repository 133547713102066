import ImagesSection from '../../../components/generals/contact/section/images.section';
import { NewsCard } from '../../../components/generals/news/NewsCard';

export const ContactCorporation = ({ contact }) => {
	return (
		<>
			<div className='px-5 py-2 my-3 rounded blue-color items-center flex justify-between'>
				<p className='text-white uppercase block'> - INFORMATIONS GENERALES </p>
			</div>
			<div className='flex justify-between flex-wrap p-4 items-start'>
				<div className='grid grid-cols-1 lg:grid-rows-1 gap-5  w-full sm:w-1/3'>
					<div className='flex items-center space-x-1'>
						<p className='text-[#2d5c6a] w-1/4'>Raison sociale :</p>
						<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-auto'>
							{contact?.company_name}
						</div>
					</div>
					<div className='flex items-center space-x-1'>
						<p className='text-[#2d5c6a] w-1/4'>Forme légale :</p>
						<div className='uppercase w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-auto'>
							{contact?.legal_form}
						</div>
					</div>
					<div className='flex items-center space-x-1'>
						<p className='text-[#2d5c6a] w-1/4'>Addresse :</p>
						<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-auto'>
							{contact?.adress_contact}
						</div>
					</div>
					<div className='flex items-start space-x-1'>
						<p className='text-[#2d5c6a] w-1/4'>Ville :</p>
						<div className='flex flex-col gap-y-2 justify-center w-[70%] mx-auto'>
							<div className='w-full mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								{contact?.city_contact}
							</div>
							<div className='w-full mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								{contact?.zip_contact}
							</div>
						</div>
					</div>
					<div className='flex items-center space-x-0.5'>
						<p className='text-[#2d5c6a] w-1/4'>Téléphone :</p>
						<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
							{contact?.phone}
						</div>
					</div>
					<div className='flex items-center space-x-1'>
						<p className='text-[#2d5c6a] w-1/4'>Email :</p>
						<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
							{contact?.mail}
						</div>
					</div>
					<div className='flex items-center space-x-1'>
						<p className='text-[#2d5c6a] w-1/4'>Agence :</p>
						<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
							{contact?.agency?.nameAgency}
						</div>
					</div>
					<div className='flex items-center space-x-1'>
						<p className='text-[#2d5c6a] w-1/4'>Négociateur :</p>
						<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
							{contact?.negociator?.name}
						</div>
					</div>
				</div>

				<div className='w-full sm:w-2/3 mt-6'>
					<p className='text-[#2d5c6a] text-center'>
						Plus d'infos d'actualités :
					</p>

					<div className='mx-2'>
						{contact.historiques &&
							contact.historiques
								.reverse()
								.map((item, key) => <NewsCard news={item} key={key} />)}
					</div>
				</div>
			</div>

			<div className='px-5 py-2 mt-5 rounded blue-color items-center flex justify-between'>
				<p className='text-white uppercase block'> - RAPPROCHEMENTS </p>
			</div>
			<ImagesSection contact={contact} />
		</>
	);
};
