import { TYPE_OFFRE } from '../../../../helpers/forms/type';

export const Pricing = ({ bien }) => {
	console.log(bien);
	return (
		<ul>
			<li>
				{bien.type_offert_id == TYPE_OFFRE.VENTE ? 'Prix' : 'Loyer'} :{' '}
				{bien.type_offert_id == TYPE_OFFRE.VENTE
					? bien.publish_price
					: bien.rent}{' '}
				€ FAI
			</li>
			{(bien.type_offert_id == TYPE_OFFRE.VENTE && (
				<li>
					Hors Honoraires : {parseInt(bien.publish_price - bien.selling_price)}{' '}
					€ ( soit{' '}
					{(
						((bien.publish_price - bien.selling_price) * 100) /
						bien.publish_price
					).toFixed(1)}
					% )
					<br />
					<small>Honoraire {bien.info_financiere.honoraire}</small>
				</li>
			)) || (
				<li>
					<small>
						Format : À la{' '}
						{JSON.parse(bien.info_financiere.info_rent_encadrement).rent_format}
					</small>
				</li>
			)}
		</ul>
	);
};
