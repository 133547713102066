import { useAgent } from '../../service/agency/useAgent';
import { Annonces } from './Json/Bien/Annonces';
import { Availabilities } from './Json/Bien/Availabilities';
import { Bien } from './Json/Bien/Bien';
import { Diagnostics } from './Json/Bien/Diagnostics';
import { Diffusion } from './Json/Bien/Diffusion';
import { Equipements } from './Json/Bien/Equipements';
import { ExteriorDetail } from './Json/Bien/ExteriorDetail';
import { Image } from './Json/Bien/Image';
import { InformationCopropriete } from './Json/Bien/InformationCopropriete';
import { InformationFinanciere } from './Json/Bien/InformationFinanciere';
import { InteriorDetail } from './Json/Bien/InteriorDetail';
import { InvestissementLocatif } from './Json/Bien/InvestissementLocatif';
import { Mandat } from './Json/Bien/Mandat';
import { RecentConstruct } from './Json/Bien/RecentConstruct';
import { Sector } from './Json/Bien/Sector';
import { Terrain } from './Json/Bien/Terrain';
import { TypeBien } from './Json/TypeBien';
import { TypeOffert } from './Json/TypeOffert';
import { TypeOffertSuite } from './Json/TypeOffertSuite';

export const BienForm = {
	completeText: 'Ajouter un bien',
	title: 'Ajouter un bien',
	steps: [
		{
			group: 'info_personnelle',
			title: 'Information principales',
			elements: [TypeOffert, TypeOffertSuite, TypeBien],
			substeps: [
				{
					group: 'info_complementaire',
					title: 'Information principales',
					elements: Bien,
				},
			],
		},
		{
			group: 'info_personnelle',
			title: 'Information détails Intérieur',
			elements: InteriorDetail,
			substeps: [
				{
					group: 'info_complementaire',
					title: 'Information détails exterieur',
					elements: ExteriorDetail,
				},
			],
		},
		{
			group: 'info_personnelle',
			title: 'Information terrain',
			elements: Terrain,
			substeps: [
				{
					group: 'info_complementaire',
					title: 'Informations copropriété',
					elements: InformationCopropriete,
				},
			],
		},
		{
			group: 'info_personnelle',
			title: 'Information equipements',
			elements: Equipements,
		},
		{
			group: 'info_personnelle',
			title: 'Information construction récente',
			elements: RecentConstruct,
		},
		{
			group: 'info_personnelle',
			title: 'Information diagnostique',
			elements: Diagnostics,
		},
		{
			// Dossier & disponiblité
			group: 'dossier',
			title: 'Dossier & disponiblité',
			elements: Availabilities,
			substeps: [
				{
					// secteur
					group: 'sector',
					title: 'Secteur',
					elements: Sector,
				},
			],
		},
		{
			// photos
			group: 'Photos',
			title: 'Photos',
			elements: Image,
			substeps: [
				{
					// mandat
					group: 'mandat',
					title: 'Mandat/Prix',
					elements: Mandat,
				},
				{
					// information financier
					group: 'info_financiers',
					title: 'Informations Financières',
					elements: InformationFinanciere,
				},
				{
					group: 'info_financiers',
					title: 'Investissement Locatif',
					elements: InvestissementLocatif,
				},
			],
		},
		{
			// rentalInvest
			group: 'info_financiers',
			title: 'Externalisation',
			elements: Annonces,
			substeps: [
				{
					group: 'diffusuion',
					title: 'Diffusion',
					elements: Diffusion,
				},
			],
		},
	],
};
