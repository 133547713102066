import { TYPE_OFFRE_TEXT } from '../../../helpers/forms/type';
import { useSuiviDossier } from '../../../service/suivi/useSuiviDossier';
import { successToast } from '../../../service/toast/showToast';
import { Modal } from '../../commons/modal/modal';

export const NotificationStep = ({ step, isOpen, handleClose }) => {
	const validateStep = () => {
		useSuiviDossier.validateStep(step).then(() => {
			successToast('Étape validée');
			handleClose();
		});
	};
	const invalidateStep = () => {
		useSuiviDossier.reportStep(step).then(() => {
			successToast('Étape reportée');
			handleClose();
		});
		handleClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			allowBackdrop={false}
			allowForceClose={false}
			modalTitle={"Notification de rappel sur l'étape : " + step.step_name}
			height='h-auto'>
			<div className='p-4'>
				<p className='text-center'>
					Cette notification vous a été envoyée parce que vous avez ajouté une
					étape sur le dossier <b>numéro {step.folder.num_mandat}</b>.<br />
					Ce dossier concerne une{' '}
					{TYPE_OFFRE_TEXT(step.folder.bien.type_offert_id)}.<br />
					Vous devez, soit valider cette étape, soit la reporter à plus tard.
				</p>
				<div className='flex justify-center gap-8 font-bold mt-8'>
					<button
						className='text-white default-color px-4 py-2 rounded-md'
						onClick={validateStep}>
						ÉTAPE VALIDÉE
					</button>
					<button
						className='text-white blue-color px-4 py-2 rounded-md'
						onClick={invalidateStep}>
						NON VALIDÉE
					</button>
				</div>
			</div>
		</Modal>
	);
};
