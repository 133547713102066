import { useState } from 'react';

export const AutoSuggestInput = ({
	style,
	element,
	errors,
	register,
	control,
	setValue,
	dataUpdate
}) => {
	const [suggestions, setSuggestions] = useState(element.suggestions);

	const choiceSuggestion = (value) => {
		setSuggestions(suggestions.filter((item) => item != value));

		const currentValue = document.querySelector(`[name=${element.name}]`).value;
		setValue(
			element.name,
			(currentValue + ',' + value).replace(/(^,)|(,$)/g, '')
		);
	};

	return (
		<>
			<input
				type='text'
				id={element.name}
				defaultValue={dataUpdate && dataUpdate[element.name] ? dataUpdate[element.name] : ''}
				{...register(element.name, { ...element.validation })}
				className={style.simple__input}
			/>
			{suggestions.map((item, index) => {
				return (
					<span
						className={style.suggestion}
						key={index}
						onClick={() => choiceSuggestion(item)}>
						{item}
					</span>
				);
			})}
		</>
	);
};
