import { imageAgent } from '../../../../helpers/image.source.';
import { CommentPige } from '../../pige/comment';

export const VisiteBloc = ({ visite }) => {
	return (
		<div className='w-full border-2 border-gray-500 shadow-xl my-2 p-2 flex gap-4 relative'>
			<div className='absolute top-2 -right-2 text-center  text-white cursor-pointer flex flex-wrap'>
				<div className='w-full bg-[#2577ff] p-2 rounded-t-xl rounded-br-xl'>
					<i className='fa-solid fa-comment-dots mr-2'></i> {visite.date_visite}
				</div>
				<div
					className='w-full'
					style={{
						backgroundImage:
							"url('https://app.pige-online.fr/images/featured.svg')",
						backgroundRepeat: 'no-repeat',
						width: '16px',
						height: '16px',
					}}></div>
			</div>

			<div class='w-[15%] sm:flex hidden items-center justify-center rounded'>
				<img
					src={
						visite.agent?.photo_url
							? imageAgent(
									`/${visite.agent.agency_id}/${visite.agent.photo_url}`
							  )
							: '/images/agents/8380015.jpg'
					}
					alt='photos original'
					class='h-full object-cover rounded'
				/>
			</div>

			<div className='w-full shadow-xl p-2'>
				<h1>
					<u>Compte rendu :</u>
					<p>{visite.comment}</p>
				</h1>
			</div>
		</div>
	);
};
