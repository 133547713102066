import { useEffect, useState } from 'react';
import { Breadcumbs } from '../../components/generals/headers/breadcumbs';
import { FilterSection } from '../suivi/FilterSection';
import BienService from '../../service/bien/BienService';
import { Pagination } from '../../components/commons/pagination';
import { useLocation, useParams } from 'react-router-dom';
import { BienSection } from '../../components/generals/biens/section/bien.section';
import { ButtonSpinner } from '../../components/commons/buttonSpinner';
import { Modal } from '../../components/commons/modal/modal';
import { getStorageUser } from '../../service/localStorage';
import { successToast } from '../../service/toast/showToast';

export const VisiteBien = () => {
	const [biens, setBiens] = useState([]);
	const [pagination, setPagination] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const { filter } = useParams();
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(true);
	const [usePath, setUsePath] = useState(false);
	const [useLink, setUseLink] = useState('');
	const [openModal, setOpenModal] = useState(false);
	const [selectedEstate, setSelectedEstate] = useState();

	const [comment, setComment] = useState({
		date_visite: null,
		comment: null,
		id_bien: null,
		bon_visite: false,
		agent_id: getStorageUser().user.id,
	});

	const sendComment = (e) => {
		e.preventDefault();
		setLoading(true);
		BienService.createVisite(comment)
			.then(({ data }) => {
				successToast('Un compte rendu de visite a été enregistré avec succès');
				setOpenModal(false);
			})
			.finally(() => setLoading(false));
	};

	const location = useLocation();
	useEffect(() => {
		fetchBiens();
		setUsePath(
			location.pathname === '/mes-rapprochements/biens' ? true : false
		);
	}, [currentPage, filter, search, location]);

	useEffect(() => {
		if (usePath === true) {
			setUseLink('/mes-rapprochements/rapprochement-bien/');
		}
	}, [usePath, location]);

	const fetchBiens = (page) => {
		setLoading(true);
		const params = Object.entries(search)
			.map(
				([key, value]) =>
					`${encodeURIComponent(key)}=${encodeURIComponent(value)}`
			)
			.join('&');
		const filterParams = filter ? `result=all&filter=${filter}` : 'result=all';
		BienService.listBien(page, filterParams, params)
			.then((response) => {
				setBiens(response.data.data);
				setPagination({
					links: response.data.links,
					per_page: response.data.per_page,
					total: response.data.total,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const handleChangePage = (page) => setCurrentPage(page);
	const handleFilterChange = (filterValue) => {
		setSearch(filterValue);
	};
	return (
		<>
			<Breadcumbs title="Compte-rendu de la visite d'un bien" />

			<div className='flex flex-wrap justify-between border-2 border-solid border-black p-2 min-h-[200px]'>
				<FilterSection onFilterChange={handleFilterChange} />

				<div className='w-full border-1 border-solid border-black m-1'>
					{biens &&
						biens.length !== 0 &&
						biens.map((bien, index) => {
							return (
								<BienSection
									bien={bien}
									key={index}
									action={() => {
										setOpenModal(true);
										setSelectedEstate(bien);
										setComment({ ...comment, id_bien: bien.id_bien });
									}}
								/>
							);
						})}
					{loading ? (
						<div className='flex items-center justify-center w-full'>
							<ButtonSpinner dimension='w-24 h-24' showLoading={loading} />
						</div>
					) : (
						biens.length === 0 && (
							<div className='flex flex-wrap justify-center h-full items-center'>
								<h1 className='text-4xl'>Aucun bien n'est disponible</h1>
							</div>
						)
					)}

					{biens.length !== 0 &&
						pagination.links &&
						pagination.links.length !== 0 && (
							<div className='h-12 mt-10 flex flex-wrap w-full gap-1 justify-center items-center'>
								{pagination.links
									.slice(1, pagination.links.length)
									.slice(0, -1)
									.map((link, index) => {
										return (
											<Pagination
												changePage={handleChangePage}
												key={index}
												link={link}></Pagination>
										);
									})}
							</div>
						)}
				</div>
			</div>

			{selectedEstate && (
				<Modal
					isOpen={openModal}
					modalTitle='Visite du bien'
					id={'visite-estate'}
					width='w-2/3'
					height='h-auto'
					allowBackdrop={false}
					handleClose={() => setOpenModal(false)}>
					<div>
						<form onSubmit={sendComment}>
							<label htmlFor='date_visite' className='w-full block font-bold'>
								Date de la visite :
							</label>
							<input
								type='date'
								name='date_visite'
								id='date_visite'
								className='w-full border-2 border-grey p-2'
								onChange={({ target }) =>
									setComment({ ...comment, date_visite: target.value })
								}
							/>
							<label htmlFor='comment' className='w-full block font-bold mt-4'>
								Laisser un compte-rendu de la visite :
							</label>

							<textarea
								name='comment'
								className='w-full border-2 border-grey p-2 h-50'
								rows={6}
								onChange={({ target }) =>
									setComment({ ...comment, comment: target.value })
								}></textarea>

							<label
								htmlFor='comment'
								className='w-full font-bold flex items-center gap-2'>
								Avez-vous signé un bon de visite avec le client ? :
								<input
									type='checkbox'
									name='bon_visite'
									onChange={({ target }) =>
										setComment({ ...comment, bon_visite: target.checked })
									}
								/>
							</label>

							<button
								type='submit'
								disabled={loading}
								className='cursor-pointer default-color text-white font-bold py-2 px-4 rounded-sm flex gap-3 items-center mt-8'>
								Enregistrer{' '}
								<ButtonSpinner dimension='w-4 h-4' showLoading={loading} />
							</button>
						</form>
					</div>
				</Modal>
			)}
		</>
	);
};
