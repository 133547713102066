import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/bundle';

export const Carousel = ({ images, onClick }) => {
	return (
		<div className='relative overflow-hidden'>
			<Swiper
				modules={[Autoplay, Navigation, Pagination]}
				spaceBetween={50}
				slidesPerView={2}
				autoplay={{
					delay: 2000,
					pauseOnMouseEnter: true
				}}
				navigation={{
					enabled: true
				}}
				loop={true}>
				{images.map((item, index) => (
					<SwiperSlide key={index}>
						<img
							src={item}
							alt={`Image ${index}`}
							onClick={() => onClick(item)}
							className='w-full object-contain rounded-md h-96'
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};
