import { BlocFilter } from '../../components/generals/biens/bloc/blocFilter';
import { BlocLine } from '../../components/generals/biens/bloc/blocLine';
import React, { useState, useEffect } from 'react';
import BienService from '../../service/bien/BienService';
import PaginationComponent from '../../components/generals/paginator/PaginationComponent';
import { useLocation, useParams } from 'react-router-dom';
import { Spinner } from '../../components/commons/Spinner';
import { FilterSection } from '../../components/generals/biens/section/filter.section';
import { BienSection } from '../../components/generals/biens/section/bien.section';
import { ButtonSpinner } from '../../components/commons/buttonSpinner';
import { Pagination } from '../../components/commons/pagination';
import { Breadcumbs } from '../../components/generals/headers/breadcumbs';

const FILTERS = {
	default: 'Gestion de mes biens',
	archive: 'Biens archivés',
	active: 'Biens actifs',
	inactive: 'Biens inactifs',
	sold: 'Biens vendus ou loués'
};

export const BienActionPage = () => {
	const [biens, setBiens] = useState([]);
	const [pagination, setPagination] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const { filter } = useParams();
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(true);
	const [usePath, setUsePath] = useState(false);
	const [useLink, setUseLink] = useState('');

	const location = useLocation();
	useEffect(() => {
		fetchBiens(currentPage);
		setUsePath(
			location.pathname === '/mes-rapprochements/biens' ? true : false
		);
	}, [currentPage, filter, search, location]);

	useEffect(() => {
		if (usePath === true) {
			setUseLink('/mes-rapprochements/rapprochement-bien/');
		}
	}, [usePath, location]);

	const fetchBiens = (page) => {
		setLoading(true);
		const params = Object.entries(search)
			.map(
				([key, value]) =>
					`${encodeURIComponent(key)}=${encodeURIComponent(value)}`
			)
			.join('&');
		const filterParams = filter ? `result=all&filter=${filter}` : 'result=all';
		BienService.listBien(page, filterParams, params)
			.then((response) => {
				setBiens(response.data.data);
				setPagination({
					links: response.data.links,
					per_page: response.data.per_page,
					total: response.data.total
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const handleChangePage = (page) => setCurrentPage(page);

	const handleFilterChange = (filterValue) => {
		setSearch(filterValue);
	};

	return (
		<>
			<Breadcumbs title={FILTERS[filter] ? FILTERS[filter] : FILTERS.default} />
			<div className='flex flex-wrap justify-between border-2 border-solid border-black p-2 min-h-[200px]'>
				<FilterSection onFilterChange={handleFilterChange} />
				<div className='w-full border-1 border-solid border-black m-1'>
					{biens &&
						biens.length !== 0 &&
						biens.map((bien, index) => {
							return <BienSection bien={bien} key={index} link={useLink} />;
						})}
					{loading ? (
						<div className='flex items-center justify-center w-full'>
							<ButtonSpinner dimension='w-24 h-24' showLoading={loading} />
						</div>
					) : (
						biens.length === 0 && (
							<div className='flex flex-wrap justify-center h-full items-center'>
								<h1 className='text-4xl'>Aucun bien n'est disponible</h1>
							</div>
						)
					)}

					{biens.length !== 0 &&
						pagination.links &&
						pagination.links.length !== 0 && (
							<div className='h-12 mt-10 flex flex-wrap w-full gap-1 justify-center items-center'>
								{pagination.links
									.slice(1, pagination.links.length)
									.slice(0, -1)
									.map((link, index) => {
										return (
											<Pagination
												changePage={handleChangePage}
												key={index}
												link={link}></Pagination>
										);
									})}
							</div>
						)}
				</div>
			</div>
		</>
	);
};
